import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../components/baseUrl";
import SellersSidebar from "../dashboardComponents/SideBar";
import "./transaction.css";
import dayjs from "dayjs";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import { AppContext } from "../../../components/AppState";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewTransaction = () => {
  const [viewOrder, setViewOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const { orderId } = useParams();
  const { user, userLoading } = useContext(AppContext);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const showOrder = () => {
    setLoading(true);
    axiosInstance.get(`/order/${orderId}`).then((response) => {
      setViewOrder(response.data.data);
      setLoading(false);
    });
  };

  const updateOrder = async (orderId) => {
    try {
      const { data } = await axiosInstance.patch("/order", {
        status: "PROCESSING",
        orderID: orderId,
      });
      toast.success(`PAYMENT CONFIRMED`, {
        position: "top-right",
        autoClose: 2000,
        pauseHover: true,
        draggable: true,
      });
      setViewOrder(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  if (loading || userLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>Transaction Details</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            {/* <div className="order-status">{viewOrder.status}</div> */}
            <div className="main-overview">
              <div className="overview-card row">
                <h2 className="mx-3">Order Information & History</h2>
                <div className="col-lg-6 py-4">
                  <table className="table table-borderless table-striped">
                    <tbody>
                      <tr>
                        <td className="first-td">Order Status:</td>
                        <td className="second-td">
                          <div style={{ width: "100px" }}>
                            {viewOrder.status === "PENDING" && (
                              <span className="badge rounded-pill bg-warning text-dark">
                                Pending
                              </span>
                            )}
                            {viewOrder.status === "PROCESSING" && (
                              <span className="badge rounded-pill bg-primary">
                                Processing
                              </span>
                            )}
                            {viewOrder.status === "SHIPPED" && (
                              <span className="badge rounded-pill bg-info text-dark">
                                Shipped
                              </span>
                            )}
                            {viewOrder.status === "DELIVERED" && (
                              <span className="badge rounded-pill bg-success">
                                Delivered
                              </span>
                            )}

                            {viewOrder.status === "CANCELLED" && (
                              <span className="badge rounded-pill bg-danger">
                                Cancelled
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Order Number:</td>
                        <td className="second-td">{viewOrder.orderNumber}</td>
                      </tr>
                      <tr>
                        <td className="first-td">Buyer's Name:</td>
                        <td className="second-td">
                          {viewOrder.User && viewOrder.User.firstName}{" "}
                          {viewOrder.User && viewOrder.User.LastName}
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Buyer's Email:</td>
                        <td className="second-td">
                          {viewOrder.User && viewOrder.User.email}
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Product Name:</td>
                        <td className="second-td">
                          {" "}
                          {viewOrder.product
                            ? viewOrder.product.productName
                            : viewOrder.productName}
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Quantity:</td>
                        <td className="second-td">
                          {viewOrder.quantityOrdered}
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Cost:</td>
                        <td className="second-td">{viewOrder.cost} USD</td>
                      </tr>
                      <tr>
                        <td className="first-td">Seller's Name:</td>
                        <td className="second-td">
                          {viewOrder.product &&
                            viewOrder.product.createdBy.firstName}{" "}
                          {viewOrder.product &&
                            viewOrder.product.createdBy.LastName}
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Seller's Email:</td>
                        <td className="second-td">
                          {viewOrder.product &&
                            viewOrder.product.createdBy.email}
                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">Incoterms:</td>
                        <td className="second-td">{viewOrder.incoterm}</td>
                      </tr>
                      <tr>
                        <td className="first-td">Payment Terms:</td>
                        <td className="second-td">{viewOrder.paymentTerm}</td>
                      </tr>
                      <tr>
                        <td className="first-td">Destination:</td>
                        <td className="second-td">{viewOrder.destination}</td>
                      </tr>
                      <tr>
                        <td className="first-td">Destination Port:</td>
                        <td className="second-td">{viewOrder.port}</td>
                      </tr>
                      <tr>
                        <td className="first-td">Notes:</td>
                        <td className="second-td">{viewOrder.note}</td>
                      </tr>
                      <tr>
                        <td className="first-td">Order Date:</td>
                        <td className="second-td">
                          {dayjs(viewOrder.createdAt).format("D MMMM YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-5 offset-1 py-4">
                  <div className="rowoverv">
                    <div className="col-12" style={{ maxWidth: "100%" }}>
                      <div className="tracker">
                        <div style={{ width: "80%" }}>
                          {/* <h2>Order History</h2> */}
                          <div className="d-flex">
                            <div className="indicator"></div>
                            <h6 className="heading">Order Placed</h6>
                          </div>
                          <div className="side-tracker">
                            <p className="texts">
                              Placed order for {viewOrder.quantityOrdered}MT of{" "}
                              {viewOrder.product
                                ? viewOrder.product.productName
                                : viewOrder.productName}{" "}
                              to be delivered to {viewOrder.port} in{" "}
                              {viewOrder.destination}
                            </p>
                          </div>

                          {/* ends here */}
                          {viewOrder.paymentTerm === "DP" ||
                          viewOrder.paymentTerm === "CAD" ? (
                            <div>
                              <div className="d-flex">
                                <div
                                  className={
                                    viewOrder.status === "PENDING"
                                      ? "yellowindicator"
                                      : "indicator"
                                  }
                                ></div>
                                <h6 className="heading">Payment</h6>
                              </div>
                              <div className="side-tracker">
                                <div className="texts">
                                  Payment Receipt is not available for{" "}
                                  {viewOrder.paymentTerm} Payment Term.
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="d-flex">
                                <div
                                  className={
                                    viewOrder.status === "PENDING"
                                      ? "yellowindicator"
                                      : "indicator"
                                  }
                                ></div>
                                <h6 className="heading">Payment</h6>
                              </div>
                              <div className="side-tracker">
                                <div className="texts">
                                  <p className="texts">
                                    {viewOrder.status === "PENDING"
                                      ? "Yet to confirm payment"
                                      : "Payment confirmed"}
                                  </p>
                                </div>
                                {viewOrder.paymentReceipt ? (
                                  <div className="d-flex mb-4">
                                    <a
                                      href={viewOrder.paymentReceipt.image}
                                      download
                                      className="texts download-payment"
                                    >
                                      View payment receipt
                                    </a>

                                    {viewOrder.status === "PENDING" ? (
                                      <div className="px-2">
                                        <button
                                          className="btn btn-success confirm-payment-btn"
                                          onClick={() =>
                                            updateOrder(viewOrder.id)
                                          }
                                        >
                                          {" "}
                                          Confirm Payment
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : (
                                  <div className="payment">
                                    No payment receipt
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="d-flex">
                            <div
                              className={
                                viewOrder.status === "PROCESSING"
                                  ? "yellowindicator"
                                  : viewOrder.status === "PENDING"
                                  ? "grayindicator"
                                  : "indicator"
                              }
                            ></div>
                            <h6
                              className={
                                viewOrder.status === "PENDING" ||
                                viewOrder.status === "PROCESSING"
                                  ? "heading opacity"
                                  : "heading"
                              }
                            >
                              Order Shipped
                            </h6>
                          </div>
                          <div className="side-tracker">
                            <p
                              className={
                                viewOrder.status === "PENDING" ||
                                viewOrder.status === "PROCESSING"
                                  ? "texts opacity"
                                  : "texts"
                              }
                            >
                              Order for {viewOrder.quantityOrdered}MT of{" "}
                              {viewOrder.product
                                ? viewOrder.product.productName
                                : viewOrder.productName}{" "}
                              has been shipped
                            </p>
                          </div>
                          <div className="d-flex">
                            <div
                              className={
                                viewOrder.status === "SHIPPED"
                                  ? "yellowindicator"
                                  : viewOrder.status === "PENDING" ||
                                    viewOrder.status === "PROCESSING"
                                  ? "grayindicator"
                                  : "indicator"
                              }
                            ></div>
                            <h6
                              className={
                                viewOrder.status === "PENDING" ||
                                viewOrder.status === "PROCESSING" ||
                                viewOrder.status === "SHIPPED"
                                  ? "heading opacity"
                                  : "heading"
                              }
                            >
                              Order Delivered
                            </h6>
                          </div>
                          <div className="side-tracker">
                            <p
                              className={
                                viewOrder.status === "PENDING" ||
                                viewOrder.status === "PROCESSING" ||
                                viewOrder.status === "SHIPPED"
                                  ? "texts opacity"
                                  : "texts"
                              }
                            >
                              Order for {viewOrder.quantityOrdered}MT of{" "}
                              {viewOrder.product
                                ? viewOrder.product.productName
                                : viewOrder.productName}{" "}
                              has been delivered to {viewOrder.port} in{" "}
                              {viewOrder.destination}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(ViewTransaction);
