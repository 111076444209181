import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../components/baseUrl";
import SellersSidebar from "../dashboardComponents/SideBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../../components/AppState";

const CreateCategories = () => {
  const [category, setCategory] = useState({
    categoryName: "",
    slug: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState("");
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState("");
  const [categories, setCategories] = useState([]);

  const handleChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  const handleId = (e) => {
    setCategoryId({ ...categoryId, [e.target.name]: e.target.value });
  };

  const { user, userLoading } = useContext(AppContext);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const jsonData = {
        categoryName: category.categoryName,
        parentId: categoryId.categoryId,
        slug: category.slug,
        description: category.description,
      };
      const formData = new FormData();
      for (const property in jsonData) {
        formData.append(`${property}`, jsonData[property]);
      }
      formData.append("image", image);
      formData.append("icon", icon);
      await axiosInstance.post("/category", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/parent-category");
      }, 2000);
      toast.success("successfully created Category", {
        position: "top-right",
        autoClose: 2000,
        pauseHover: true,
        draggable: true,
      });
    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 4000,
        pauseHover: true,
        draggable: true,
      });
    }
  };
  const getCategory = async () => {
    try {
      axiosInstance.get("/category").then((response) => {
        setCategories(response.data.data.categories);
      });
    } catch (error) {
      console.log(error.response.data.erros);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  if (userLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="grid-container">
          <ToastContainer />
          <header className="header">
            <div className="header__message me-5">
              <h2>Create New Category</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>Category Name</label>
                      <input
                        className="form-control my-2"
                        placeholder="Category Name"
                        name="categoryName"
                        value={category.categoryName}
                        onChange={handleChange}
                      />
                      <div className="my-4">
                        <label>Slug</label>
                        <input
                          className="form-control mt-1"
                          placeholder="Slug"
                          name="slug"
                          value={category.slug}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="my-4">
                        <select
                          className="form-control select-option"
                          name="categoryId"
                          onChange={handleId}
                        >
                          <option defaultValue={true}>
                            ...Select Parent Category
                          </option>
                          <option value={""}>None</option>
                          {categories &&
                            categories.map((category) => (
                              <option value={category.id} key={category.id}>
                                {category.category}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="my-4">
                        <label>Description</label>
                        <textarea
                          className="form-control my-2 select-pcategory mt-1"
                          name="description"
                          value={category.description}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="my-3">
                        <p>Upload Category Image*</p>

                        <div
                          className="file-upload my-2"
                          onClick={() =>
                            document.querySelector(".input-field").click()
                          }
                        >
                          <i
                            className="fa fa-upload  upload-text"
                            aria-hidden="true"
                          ></i>
                          <div className="upload-text mx-2">Add file</div>

                          <input
                            name="iamge"
                            className="input-field"
                            onChange={(e) => setImage(e.target.files[0])}
                            type="file"
                            hidden
                            id="categoryimage"
                          />
                        </div>
                        <p>{image && image.name}</p>
                        <p>Upload Category Icon*</p>
                        <div
                          className="file-upload my-2"
                          onClick={() =>
                            document.querySelector(".inputt-field").click()
                          }
                        >
                          <i
                            className="fa fa-upload  upload-text"
                            aria-hidden="true"
                          ></i>
                          <div className="upload-text mx-2">Add file</div>

                          <input
                            name="icon"
                            className="inputt-field"
                            onChange={(e) => setIcon(e.target.files[0])}
                            type="file"
                            hidden
                            id="categoryimage"
                          />
                        </div>
                        <p>{icon && icon.name}</p>
                      </div>

                      {loading ? (
                        <button type="submit" className="btn btn-dark">
                          <span
                            className="spinner-border spinner-border-sm mx-4"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button className="btn btn-dark px-4" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default CreateCategories;
