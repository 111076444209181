import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../components/baseUrl";
import SellersSidebar from "../dashboardComponents/SideBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../../components/AppState";

const EditCategory = () => {
  const [category, setCategory] = useState("");
  const [id, setId] = useState(null);
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const { user, userLoading } = useContext(AppContext);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getCategory = async () => {
    try {
      const response = await axiosInstance.get(`/category/${categoryId}`);
      setCategory(response.data.data.category);
      setSlug(response.data.data.slug);
      setDescription(response.data.data.description);
      setId(response.data.data.id);
      setFeaturedImage(response.data.data.image);
      setIcon(response.data.data.icon);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleUpdate = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();

      const jsonData = {
        category: category,
        slug: slug,
        description: description,
      };
      const formData = new FormData();
      for (const property in jsonData) {
        formData.append(`${property}`, jsonData[property]);
      }
      formData.append("image", image);
      formData.append("icon", icon);
      await axiosInstance.patch(`/category/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
      toast.success("Successfully Edited Category", {
        position: "top-right",
        autoClose: 2000,
        pauseHover: true,
        draggable: true,
      });
    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 4000,
        pauseHover: true,
        draggable: true,
      });
    }
  };

  if (loader || userLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div>
        <div className="grid-container">
          <ToastContainer />
          <header className="header">
            <div className="header__message me-5">
              <h2>Edit Category</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <form onSubmit={handleUpdate}>
                    <div>
                      <label>Category Name</label>
                      <input
                        className="form-control my-4"
                        type="text"
                        onChange={(e) => setCategory(e.target.value)}
                        value={category}
                      />

                      <div className="my-4">
                        <label>Slug</label>
                        <input
                          className="form-control mt-1"
                          placeholder="Slug"
                          name="slug"
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                        />
                      </div>

                      <div className="my-4">
                        <label>Description</label>
                        <textarea
                          className="form-control my-2 select-pcategory mt-1"
                          name="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>

                      <div className="my-3">
                        <p>Upload Category Image*</p>

                        <div
                          className="file-upload my-2"
                          onClick={() =>
                            document.querySelector(".input-field").click()
                          }
                        >
                          <i
                            className="fa fa-upload  upload-text"
                            aria-hidden="true"
                          ></i>
                          <div className="upload-text mx-2">Add file</div>

                          <input
                            name="iamge"
                            className="input-field"
                            onChange={(e) => setImage(e.target.files[0])}
                            type="file"
                            hidden
                            id="categoryimage"
                          />
                        </div>
                        {image ? (
                          <div className="image-container">
                            <img
                              src={image && URL.createObjectURL(image)}
                              alt="category"
                              className="featuredimage"
                            />
                          </div>
                        ) : (
                          <div className="image-container">
                            <img
                              src={featuredImage}
                              alt="Category"
                              className="featuredimage"
                            />
                          </div>
                        )}

                        <p>{image && image.name}</p>
                        <p>Upload Category Icon*</p>
                        <div
                          className="file-upload my-2"
                          onClick={() =>
                            document.querySelector(".inputt-field").click()
                          }
                        >
                          <i
                            className="fa fa-upload  upload-text"
                            aria-hidden="true"
                          ></i>
                          <div className="upload-text mx-2">Add file</div>

                          <input
                            name="icon"
                            className="inputt-field"
                            onChange={(e) => setIcon(e.target.files[0])}
                            type="file"
                            hidden
                            id="categoryimage"
                          />
                        </div>
                        <p>{icon && icon.name}</p>
                      </div>

                      {loading ? (
                        <button type="submit" className="btn btn-dark">
                          <span
                            className="spinner-border spinner-border-sm mx-4"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button className="btn btn-dark">Submit</button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
