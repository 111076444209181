import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../components/AppState";
import { useNavigate, useParams } from "react-router-dom";
import SellersSidebar from "../dashboardComponents/SideBar";
import "./rfq.css";
import roundLogo from "../../../assets/img/round-logo.png";
import { axiosInstance } from "../../../components/baseUrl";

const ViewRfq = () => {
  const [viewRfq, setViewRfq] = useState([]);
  const [loading, setLoading] = useState(true);
  const { rfqId } = useParams();
  const navigate = useNavigate();

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const { user, userLoading } = useContext(AppContext);

  const showRfq = async () => {
    setLoading(true);
    await axiosInstance
      .get(`/rfq/${rfqId}`)
      .then((response) => {
        setViewRfq(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
      });
  };

  useEffect(() => {
    showRfq();
  }, []);

  if (loading || userLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>RFQ Details</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <div className="main-overview mx-2">
              <div className="row rfq-details">
                <div className="overview-card col-lg-8 col-md-10 col-12">
                  <div className="text-center">
                    <div>
                      <img src={roundLogo} alt="round-logo" />
                    </div>
                  </div>
                  <hr />
                  <h6>Buyers Info</h6>
                  <div className="d-flex my-3">
                    <p className="bold">First Name:</p>
                    <p className="px-1">
                      {viewRfq.user && viewRfq.user.firstName}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Last Name:</p>
                    <p className="px-1">
                      {viewRfq.user && viewRfq.user.LastName}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="bold">Email:</p>
                    <p className="px-1">
                      {viewRfq.user.email && viewRfq.user.email}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="bold">Is user verified:</p>
                    <p className="px-1">
                      {viewRfq.user && viewRfq.user.isEmailVerified === true
                        ? "verified"
                        : "Not verified"}
                    </p>
                  </div>
                </div>
                <div className=" overview-card col-lg-8 col-md-10 col-12">
                  <h6>Sellers Info</h6>
                  <div className="d-flex my-3">
                    <p className="bold">First Name:</p>
                    <p className="px-1">
                      {viewRfq?.seller && viewRfq?.seller?.firstName}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Last Name:</p>
                    <p className="px-1">
                      {viewRfq?.seller && viewRfq.seller?.LastName}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="bold">Email:</p>
                    <p className="px-1">
                      {viewRfq?.seller?.email && viewRfq?.seller?.email}
                    </p>
                  </div>
                  <div className="d-flex">
                    <p className="bold">Phone number:</p>
                    <p className="px-1">
                      {viewRfq?.seller?.phoneNumber &&
                        viewRfq?.seller?.phoneNumber}
                    </p>
                  </div>
                </div>

                <div className="d-flex"></div>
                <div className=" overview-card col-lg-8 col-md-10 col-12">
                  <div className="d-flex my-3">
                    <p className="bold">RFQ ID:</p>
                    <p className="px-1">{viewRfq.id && viewRfq.id}</p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Product Name:</p>
                    <p className="px-1">
                      {viewRfq.productName && viewRfq.productName}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Target Price:</p>
                    <p className="px-1">
                      {viewRfq.targetPrice && viewRfq.targetPrice}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Payment Terms:</p>
                    <p className="px-1">
                      {viewRfq.paymentTerms && viewRfq.paymentTerms}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Destination Port:</p>
                    <p className="px-1">
                      {viewRfq.destinationPort && viewRfq.destinationPort}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Status:</p>
                    <p className="px-1">{viewRfq.status && viewRfq.status}</p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Quantity Required:</p>
                    <p className="px-1">
                      {viewRfq.quantityRequired && viewRfq.quantityRequired}
                    </p>
                  </div>

                  <div className="d-flex my-3">
                    <p className="bold">Terms of Trade:</p>
                    <p className="px-1">
                      {viewRfq.termsOfTrade && viewRfq.termsOfTrade}
                    </p>
                  </div>
                  <div className="d-flex my-3">
                    <p className="bold">Product Requirement:</p>
                    <p className="px-1">
                      {viewRfq.productDescription && viewRfq.productDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ViewRfq;
