import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../components/AppState";

import SellersSidebar from "../dashboardComponents/SideBar";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import { getSourceProTransactions } from "../../../hooks/getSourceProTransactions";
import { getSourceProStatistics } from "../../../hooks/getSourceProStatistics";
import Search from "../dashboardComponents/Search";
import PaginationComponent from "../../../components/PaginationComponent";
import { Link } from "react-router-dom";
import "./SourcePro.css";

const SourcePro = () => {
  const { user, userLoading } = useContext(AppContext);

  const {
    isLoading,
    transactionData,
    setSearch,
    setCurrentPage,
    totalItems,
    noMatch,
    ITEMS_PER_PAGE,
    currentPage,
  } = getSourceProTransactions();

  const { loading, sourceProStatistics, getAllSourceProStatistics } =
    getSourceProStatistics();

  const convertDateFormat = (oldDate) => {
    let date = new Date(oldDate).toString().split(" ");
    let newFormat = ` ${date[2]}  ${date[1]}, ${date[3]}`;
    return newFormat;
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  function numberWithCommas(x) {
    const numberToTwoDecimal = parseFloat(x.toFixed(2));
    return numberToTwoDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    getAllSourceProStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || userLoading || isLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>All Transactions</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <h1 className="section-title">Activity Summary</h1>
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <h2>Total Revenue</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {sourceProStatistics?.totalAmount
                        ? numberWithCommas(sourceProStatistics?.totalAmount)
                        : 0}{" "}
                      USD
                    </h3>
                  </div>
                </div>
              </div>

              <div className="overview-card">
                <div>
                  <h2>Total Quantity Supplied</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {sourceProStatistics?.totalQuantity
                        ? sourceProStatistics.totalQuantity
                        : 0}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>No of Transaction</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {sourceProStatistics?.totalTransactions
                        ? sourceProStatistics?.totalTransactions + 119
                        : 0}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Partners</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {sourceProStatistics?.totalUniqueCompanies
                        ? sourceProStatistics?.totalUniqueCompanies
                        : 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="source_pro_button_ctn">
              <Link
                to="/source-pro/upload-transaction"
                className="source_pro_create_button"
              >
                Upload transaction
              </Link>
            </div>
            <div className="section-title-ctn">
              <h1 className="section-title">Latest Transactions</h1>
              <div className="header__search">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>

            <div className="main-overview">
              <div className="overview-card no-padding">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date created</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Amount (USD)</th>
                        <th scope="col">quantity</th>
                        <th scope="col">Type</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData &&
                        transactionData.map((transaction, index) => {
                          return (
                            <tr key={transaction.id}>
                              <td>
                                {transaction.createdAt &&
                                  convertDateFormat(transaction.createdAt)}
                              </td>

                              <td>
                                <div className="d-flex">
                                  <div className="flex-grow-1 ms-3">
                                    <p className="text-center">
                                      {transaction.productName}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {transaction?.amount &&
                                  numberWithCommas(transaction?.amount)}
                              </td>
                              <td>
                                {transaction?.quantity && transaction?.quantity}
                              </td>
                              <td>{transaction?.type && transaction?.type}</td>
                              <td>
                                {transaction?.companyName &&
                                  transaction.companyName}
                              </td>
                              <td>
                                {transaction?.status && transaction.status}
                              </td>

                              <td>
                                <p>Update</p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {noMatch === true ? (
              <div className="empty-state">
                <h4>No results found</h4>
                <p>
                  No product matched your criteria. Try searching for something
                  else.
                </p>
              </div>
            ) : (
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SourcePro, ["SOURCE_PRO_ADMIN", "SUPER_ADMIN"]);
