import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import SellersSidebar from "../dashboardComponents/SideBar";
import { axiosInstance } from "../../../components/baseUrl";
import { AppContext } from "../../../components/AppState";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import PaginationComponent from "../../../components/PaginationComponent";
import Search from "../dashboardComponents/Search";
import dayjs from "dayjs";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [viewUser, setViewUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewLoader, setViewLoader] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { user, userLoading } = useContext(AppContext);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getUsers = async () => {
    try {
      axiosInstance.get("/auth/users").then((response) => {
        const newArr = response.data.data.filter(
          (item) =>
            !item.role.includes("SELLER") && !item.role.includes("BUYER")
        );
        setUsers(newArr);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  const adminUsers = useMemo(() => {
    let computedUsers = users;

    if (search) {
      computedUsers = computedUsers.filter(
        (comment) =>
          comment.firstName.toLowerCase().includes(search.toLowerCase()) ||
          comment.LastName.toLowerCase().includes(search.toLowerCase()) ||
          comment.role.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      );

      if (computedUsers.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedUsers.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedUsers.length);

    return computedUsers.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [users, currentPage, search]);

  const showUser = async (buyerID) => {
    try {
      setViewLoader(true);
      await axiosInstance
        .get(`/auth/users/user/${buyerID}`)
        .then((response) => {
          setViewUser(response.data.data);
          setViewLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  if (loading || userLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="grid-container">
        <header className="header">
          <div className="header__message me-5">
            <h2>Admin Users</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>
        <SellersSidebar />

        <main className="main">
          <div className="d-flex justify-content-between">
            <div>
              <h1 className="section-title">All Users</h1>
            </div>

            <div className="mb-4">
              <Link to="/settings">
                <button className="btn btn-dark"> Create User</button>
              </Link>
            </div>
          </div>
          <div className="section-title-ctn">
            <div className="header__search">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          <div className="main-overview">
            <div className="overview-card no-padding">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Role</th>
                      <th scope="col">Organization</th>
                      <th scope="col">Email</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminUsers &&
                      adminUsers.map((user, index) => {
                        return (
                          <tr key={user.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p>
                                    {user.firstName} {user.LastName}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>{user.phoneNumber}</td>
                            <td>
                              {user.role === "OLD_MUTUAL" ||
                              user.role === "AFCTCA"
                                ? "Partner"
                                : user.role}
                            </td>
                            <td>
                              {user.role === "OLD_MUTUAL"
                                ? "OLD_MUTUAL"
                                : user.role === "AFCTCA"
                                ? "AFCTCA"
                                : "TOFA"}
                            </td>

                            <td>{user.email}</td>
                            <td>
                              <Link
                                to="/n"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={(e) => showUser(user.id)}
                              >
                                View
                              </Link>

                              <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  style={{
                                    backgroundColor: "#F5F5F5",
                                  }}
                                >
                                  <div
                                    className="modal-content"
                                    style={{
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        User's Summary
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    {viewLoader ? (
                                      <div className="lds-ctnn">
                                        <div className="lds-ellipsis">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="modal-body ">
                                          <div className="row">
                                            <div className="col-lg-6 col-md-8 col-12">
                                              <div className="overview-card">
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    First Name:
                                                  </p>

                                                  <p className="px-1">
                                                    {viewUser.firstName &&
                                                      viewUser.firstName}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Last Name:
                                                  </p>

                                                  <p className="px-1">
                                                    {viewUser.LastName &&
                                                      viewUser.LastName}{" "}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">Email:</p>

                                                  <p className="px-1">
                                                    {viewUser.email &&
                                                      viewUser.email}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Phone No:
                                                  </p>

                                                  <p className="px-1">
                                                    {viewUser.phoneNumber &&
                                                      viewUser.phoneNumber}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-8 col-12">
                                              <div className="overview-card">
                                                <div className="d-flex my-3">
                                                  <p className="bold">Role:</p>

                                                  <p className="px-1">
                                                    {viewUser.role &&
                                                      viewUser.role}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">Joined</p>

                                                  <p className="px-1">
                                                    {viewUser.createdAt &&
                                                      dayjs(
                                                        viewUser.createdAt
                                                      ).format("D MMMM YYYY")}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Is email verified
                                                  </p>

                                                  <p className="px-1">
                                                    {viewUser.isEmailVerified &&
                                                    viewUser.isEmailVerified ===
                                                      true
                                                      ? "Verified"
                                                      : "Not Verified"}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Is phone No verified
                                                  </p>

                                                  <p className="px-1">
                                                    {viewUser.isPhoneVerified &&
                                                    viewUser.isPhoneVerified ===
                                                      true
                                                      ? "Verified"
                                                      : "Not Verified"}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {noMatch === true ? (
            <div className="empty-state">
              <h4>No results found</h4>
              <p>
                No search matched your criteria. Try searching for something
                else.
              </p>
            </div>
          ) : (
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(Users, [
  "SOURCE_PRO_ADMIN",
  "SUPER_ADMIN",
  "FINANCE",
  "MARKETPLACE_ADMIN",
]);
