/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./AppState";
import { axiosInstance } from "./baseUrl";

export const ProtectedRoutes = (WrappedComponent, roles) => {
  return (props) => {
    const navigate = useNavigate();
    const [verified, setVerified] = useState(false);

    const { setUser } = useContext(AppContext);

    useEffect(() => {
      axiosInstance
        .get("/auth/current-user")
        .then((response) => {
          const user = response.data.currentUser;
          setUser(user);

          if (!roles && user) {
            return setVerified(true);
          }

          const filterRoles = roles.filter((role) => {
            return role === user.role;
          });

          if (filterRoles.length > 0) {
            setVerified(true);
          } else {
            setVerified(false);
            navigate("/unauthorized");
          }
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};
