import React from "react";
import "./NetworkPage.css";
import { Link } from "react-router-dom";

const NetworkPage = () => {
  return (
    <>
      <div className="p-4 logo"></div>
      <div className="internet-ctn">
        <h1>NO INTERNET CONNECTION OR SERVER DOWN</h1>
        <p>Check your internet and be sure it's properly connected</p>

        <div>
          <Link to="/overview">
            <button className="request-quotebtn">Back to Home page</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NetworkPage;
