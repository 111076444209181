import React, { useContext } from "react";
import SellersSidebar from "../dashboardComponents/SideBar";
import { AppContext } from "../../../components/AppState";
import { usePostSourceProTransaction } from "../../../hooks/usePostSourceProTransaction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UploadSourceTransaction = () => {
  const { user } = useContext(AppContext);
  const { sourceProDetails, loading, handleChnage, handleSubmit } =
    usePostSourceProTransaction();

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div>
        <ToastContainer />
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>Upload Transactions</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <form action="" className="source-pro-ctn" onSubmit={handleSubmit}>
              <div className="row">
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="productName">Product Name</label>
                    <input
                      type="text"
                      name="productName"
                      id="productName"
                      value={sourceProDetails.productName}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="currency">Currency</label>
                    <select
                      name="currency"
                      id="currency"
                      value={sourceProDetails.currency}
                      onChange={handleChnage}
                      required
                    >
                      <option value="NGN">NGN</option>
                      <option value="XOF">XOF</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                      <option value="GHS">GHS</option>
                    </select>
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="exChangeRate">
                      Current Exchange Rate(To dollar)
                    </label>
                    <input
                      type="number"
                      name="exChangeRate"
                      id="exChangeRate"
                      value={sourceProDetails.exChangeRate}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      value={sourceProDetails.amount}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="location">Location</label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      value={sourceProDetails.location}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="quantity">Quantity</label>
                    <input
                      type="number"
                      name="quantity"
                      id="quantity"
                      value={sourceProDetails.quantity}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="type">Type</label>
                    <input
                      type="text"
                      name="type"
                      id="type"
                      value={sourceProDetails.type}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="companyName">Company Name</label>
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      value={sourceProDetails.companyName}
                      onChange={handleChnage}
                      required
                    />
                  </div>
                </div>
                <div className=" col-md-6 col-12">
                  <div className="source-pro-wrapper">
                    <label htmlFor="status">Status</label>
                    <select
                      name="status"
                      id="status"
                      value={sourceProDetails.status}
                      onChange={handleChnage}
                      required
                    >
                      <option selected>select status...</option>
                      <option value="PAID">PAID</option>
                      <option value="UNPAID">UNPAID</option>
                    </select>
                  </div>
                </div>
                <div className="source_pro_button_ctn">
                  {loading ? (
                    <button
                      type="submit"
                      className="source_pro_create_button mx-2"
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="source_pro_create_button mx-2"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </main>
        </div>
      </div>
    </>
  );
};

export default UploadSourceTransaction;
