/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../components/baseUrl";

export const getSourceProTransactions = () => {
  const [allSourceProTransactions, setAllSourceProTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [noMatch, setNoMatch] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 10;

  const navigate = useNavigate();

  const getAllSourceProTransactions = async () => {
    setIsLoading(true);
    try {
      const data = await axiosInstance.get(
        "/sourcepro-transaction/get-sourcepro-transactions"
      );
      setAllSourceProTransactions(data?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getAllSourceProTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionData = useMemo(() => {
    let computedTransactions = allSourceProTransactions;

    if (search) {
      computedTransactions = computedTransactions.filter(
        (comment) =>
          comment.productName.toLowerCase().includes(search.toLowerCase()) ||
          (comment.createdBy &&
            comment.createdBy.firstName
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          comment.productStatus.toLowerCase().includes(search.toLowerCase())
      );
      if (computedTransactions.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedTransactions.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedTransactions.length);

    return computedTransactions.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allSourceProTransactions, currentPage, search]);

  return {
    isLoading,
    transactionData,
    allSourceProTransactions,
    setSearch,
    setCurrentPage,
    totalItems,
    noMatch,
    ITEMS_PER_PAGE,
    currentPage,
  };
};
