import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppContext } from "../../../components/AppState";
import { axiosInstance } from "../../../components/baseUrl";
import PaginationComponent from "../../../components/PaginationComponent";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import Search from "../dashboardComponents/Search";
import SellersSidebar from "../dashboardComponents/SideBar";

import {
  numberWithCommas,
  Capitalize,
  convertDateFormat,
} from "../../../helpers/hellpersFunctions";
import CardsLoader from "../../../components/skeletonLoaders/CardsLoader";
import TableLoader from "../../../components/skeletonLoaders/TableLoader";

const Sellers = () => {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [viewSeller, setViewSeller] = useState({});
  const [viewLoader, setViewLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [noMatch, setNoMatch] = useState(false);
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [summary, setSummary] = useState("");
  const [summaryLoader, setSummaryLoader] = useState(true);

  const { user, userLoading } = useContext(AppContext);
  const navigate = useNavigate();

  //get all sellers
  const getSellers = async () => {
    try {
      if (
        user?.role === "SUPER_ADMIN" ||
        user?.role === "MARKETPLACE_ADMIN" ||
        user?.role === "FINANCE" ||
        user?.role === "SOURCE_PRO_ADMIN"
      ) {
        axiosInstance.get("/auth/users?role=SELLER").then((res) => {
          setSellers(res.data.data);
          setLoading(false);
        });
      } else if (user?.role === "BUYER" || user?.role === "SELLER") {
        navigate("/unauthorized");
      } else {
        axiosInstance
          .get(`/auth/users?role=SELLER&hearAboutUs=${user?.role}`)
          .then((response) => {
            setSellers(response.data.data);
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //getting all seller ativity summary
  const getSellerActivitySummary = async () => {
    try {
      if (
        user?.role === "SUPER_ADMIN" ||
        user?.role === "MARKETPLACE_ADMIN" ||
        user?.role === "FINANCE" ||
        user?.role === "SOURCE_PRO_ADMIN"
      ) {
        axiosInstance
          .get("/dashboard/admin/activity-summary")
          .then((response) => {
            setSummary(response.data.data);
            setSummaryLoader(false);
          });
      } else if (user?.role === "BUYER" || user?.role === "SELLER") {
        navigate("/unauthorized");
      } else {
        axiosInstance
          .post("/dashboard/admin/seller-activity-summary", {
            hearAboutUs: user.role,
          })
          .then((response) => {
            setSummary(response.data.data);
            setSummaryLoader(false);
          });
      }
    } catch (error) {
      console.log(error);
      setSummaryLoader(false);
    }
  };

  useEffect(() => {
    getSellerActivitySummary();
    getSellers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //filtering based on partner
  const filterSellerBasedOnPartner = async (e) => {
    const selectedPartner = e.target.value;

    setLoading(true);
    setSummaryLoader(true);
    try {
      if (selectedPartner === "sellers") {
        await axiosInstance.get("/auth/users?role=SELLER").then((response) => {
          setSellers(response.data.data);
          setLoading(false);
        });
        await axiosInstance
          .get("/dashboard/admin/activity-summary")
          .then((response) => {
            setSummary(response.data.data);

            setSummaryLoader(false);
          });
      } else {
        axiosInstance
          .get(`/auth/users?role=SELLER&hearAboutUs=${selectedPartner}`)
          .then((response) => {
            setSellers(response.data.data);
            setLoading(false);
          });
        await axiosInstance
          .post("/dashboard/admin/seller-activity-summary", {
            hearAboutUs: selectedPartner,
          })
          .then((response) => {
            setSummary(response.data.data);
            setSummaryLoader(false);
          });
      }
    } catch (error) {
      console.log(error);
      setSummaryLoader(false);
      setLoading(false);
    }
  };

  const showSeller = async (sellerID) => {
    try {
      setViewLoader(true);
      await axiosInstance
        .get(`/dashboard/seller/${sellerID}`)
        .then((response) => {
          setViewSeller(response.data.data);
          setViewLoader(false);
        });
    } catch (error) {
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
    }
  };

  const blackListSeller = async (sellerId, status) => {
    setStatusLoading(true);
    try {
      const currentStatus = status;
      await axiosInstance.patch(`/auth/seller-status`, {
        sellerStatus: currentStatus,
        userId: sellerId,
      });
      setStatusLoading(false);
      if (status === "DISAPPROVED") {
        toast.success("Seller has been Blacklisted", {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
      }
      if (status === "APPROVED") {
        toast.success("Seller has been Approved", {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      setStatusLoading(false);
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 4000,
        pauseHover: true,
        draggable: true,
      });
    }
  };

  const commentsData = useMemo(() => {
    let computedSellers = sellers;

    if (search) {
      computedSellers = computedSellers.filter(
        (comment) =>
          comment.LastName.toLowerCase().includes(search.toLowerCase()) ||
          comment.firstName.toLowerCase().includes(search.toLowerCase()) ||
          comment.sellerStatus.toLowerCase().includes(search.toLowerCase()) ||
          (comment.hearAboutUs &&
            comment.hearAboutUs.toLowerCase().includes(search.toLowerCase()))
      );
      if (computedSellers.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedSellers.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedSellers.length);

    //currentPage Slice

    return computedSellers.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [sellers, currentPage, search]);

  return (
    <>
      <ToastContainer />
      <div className="grid-container">
        <header className="header">
          <div className="header__message me-5">
            <h2>All Sellers</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>
        <SellersSidebar />

        <main className="main">
          <h1 className="section-title">Activity Summary</h1>
          {summaryLoader || userLoading ? (
            <CardsLoader />
          ) : (
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  {/* <h2>Total Confirmed Orders</h2> */}

                  <h2>Total Sellers</h2>

                  <div className="d-flex justify-content-between mt-4">
                    <h3>{summary && summary.total_sellers}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  {/* <h2>Total Transaction Revenue</h2> */}
                  <h2>Total Approved Sellers</h2>

                  <div className="d-flex justify-content-between mt-4">
                    <h3>{summary.total_approved_sellers}</h3>
                    {/* <h3>{summary.total_transactions_revenue}</h3> */}
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  {/* <h2>Total Shipped Orders</h2> */}
                  <h2>Total Disapproved Sellers</h2>
                  <div className="d-flex justify-content-between mt-4">
                    {/* <h3>{summary.total_shipped_orders}</h3> */}
                    <h3>{summary.total_disapproved_sellers}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <div className="flex items-center justify-between w-full">
              {user?.role === "SUPER_ADMIN" ||
              user?.role === "MARKETPLACE_ADMIN" ||
              user?.role === "FINANCE" ||
              user?.role === "SOURCE_PRO_ADMIN" ? (
                <div className="header__search">
                  <label className="mr-2 capitalize">
                    Filter sellers by partner
                  </label>

                  <div className="my-2">
                    <select
                      className="form-select text-xs"
                      onChange={filterSellerBasedOnPartner}
                      style={{ width: "10rem", borderRadius: "4px" }}
                    >
                      <option>Select Seller</option>
                      <option value="sellers">All Sellers</option>
                      <option value="OLD_MUTUAL">OldMutual Sellers</option>
                      <option value="AFCTCA">AFCFTA Sellers</option>
                      <option value="FARMBENE">FARMBENE Sellers</option>
                    </select>
                  </div>
                </div>
              ) : null}

              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          {loading || userLoading ? (
            <TableLoader />
          ) : (
            <>
              {/* <h1 className="section-title">All Sellers</h1> */}
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">S/N</th>
                          <th scope="col">Joined</th>
                          <th scope="col">Full Name</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Email</th>
                          <th scope="col">Hear About Us</th>
                          <th scope="col">Seller Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsData?.map((seller, index) => {
                          return (
                            <tr key={seller.id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {seller?.createdAt &&
                                  convertDateFormat(seller?.createdAt)}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-center">
                                      {seller.firstName} {seller.LastName}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {seller.phoneNumber && seller.phoneNumber}
                              </td>
                              <td>{seller.email && seller.email}</td>

                              {seller.hearAboutUs &&
                              seller.hearAboutUs === "AFCTCA" ? (
                                <td>AFCFTA</td>
                              ) : (
                                <td>{seller.hearAboutUs}</td>
                              )}
                              <td>{seller.sellerStatus}</td>
                              <td>
                                <Link
                                  to="/n"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={(e) => showSeller(seller.id)}
                                >
                                  View
                                </Link>

                                <div
                                  className="modal fade"
                                  id="exampleModal"
                                  tabIndex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div
                                    className="modal-dialog modal-lg"
                                    style={{
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <div
                                      className="modal-content"
                                      style={{
                                        backgroundColor: "#F5F5F5",
                                      }}
                                    >
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                        >
                                          Sellers Information
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      {viewLoader ? (
                                        <div className="lds-ctnn">
                                          <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="modal-body">
                                          <div className="row">
                                            <div className="col-lg-6 col-md-10 col-12">
                                              <div className="overview-card">
                                                {user.role === "AFCTCA" ||
                                                user.role ===
                                                  "OLD_MUTUAL" ? null : (
                                                  <div className="row">
                                                    <div className="col-md-6 col-sm-8 col-10">
                                                      {statusLoading ? (
                                                        <button
                                                          type="submit"
                                                          className="btn btn-dark px-4"
                                                        >
                                                          <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                          ></span>
                                                        </button>
                                                      ) : (
                                                        <>
                                                          {viewSeller?.seller
                                                            ?.sellerStatus ===
                                                          "APPROVED" ? (
                                                            <button
                                                              className={
                                                                "btn btn-danger py-2"
                                                              }
                                                              onClick={(e) =>
                                                                blackListSeller(
                                                                  viewSeller
                                                                    ?.seller
                                                                    ?.id,
                                                                  "DISAPPROVED"
                                                                )
                                                              }
                                                            >
                                                              Blacklist seller
                                                            </button>
                                                          ) : (
                                                            <button
                                                              className={
                                                                "btn btn-success py-2"
                                                              }
                                                              onClick={(e) =>
                                                                blackListSeller(
                                                                  viewSeller
                                                                    ?.seller
                                                                    ?.id,
                                                                  "APPROVED"
                                                                )
                                                              }
                                                            >
                                                              Approve seller
                                                            </button>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}

                                                <div className="d-flex my-3">
                                                  <Link
                                                    to={`https://marketplase.tradersofafrica.com/sellers-info/${viewSeller?.seller?.id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="view-seller"
                                                  >
                                                    View seller store
                                                  </Link>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    First Name:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.firstName &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.firstName
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Last Name:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.LastName &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.LastName
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Phone Number:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.phoneNumber &&
                                                      viewSeller?.seller
                                                        ?.phoneNumber}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Phone Number:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.phoneNumber &&
                                                      viewSeller?.seller
                                                        ?.phoneNumber}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">Email:</p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.email &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.email
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Business name:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.businessName &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.businessName
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Business address:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.address &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.address
                                                      )}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-10 col-12">
                                              <div className="overview-card">
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Number Of Products:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_number_of_products &&
                                                      numberWithCommas(
                                                        viewSeller?.total_number_of_products
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Transaction Revenue:
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_transactions_revenue &&
                                                      numberWithCommas(
                                                        viewSeller?.total_transactions_revenue
                                                      )}{" "}
                                                    USD
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Enquiries:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.total_awaiting_response_quote &&
                                                      numberWithCommas(
                                                        viewSeller?.total_awaiting_response_quote
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Responded Quotes:
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_responded_quote &&
                                                      numberWithCommas(
                                                        viewSeller?.total_responded_quote
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Orders:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_number_of_orders &&
                                                      numberWithCommas(
                                                        viewSeller?.total_number_of_orders
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Pending Orders:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_pending_orders &&
                                                      numberWithCommas(
                                                        viewSeller?.total_pending_orders
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Confirmed Orders:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_confirmed_orders &&
                                                      numberWithCommas(
                                                        viewSeller?.total_confirmed_orders
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Shipped Orders:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_shipped_orders &&
                                                      numberWithCommas(
                                                        viewSeller?.total_shipped_orders
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Delivered Orders:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {viewSeller?.total_delivered_orders &&
                                                      numberWithCommas(
                                                        viewSeller?.total_delivered_orders
                                                      )}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-10 col-12">
                                              <div className="overview-card">
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Joined marketplace :{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.createdAt &&
                                                      convertDateFormat(
                                                        viewSeller?.seller
                                                          ?.createdAt
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Hear about Us:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller &&
                                                      viewSeller?.seller
                                                        ?.hearAboutUs}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Country:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.country &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.country
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Supply Capacity:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.supplyCapacity &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.supplyCapacity
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Year Established:
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.yearEstablished &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.yearEstablished
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="d-flex my-3">
                                                  <p className="bold">
                                                    Business Decription:{" "}
                                                  </p>
                                                  <p className="px-1">
                                                    {" "}
                                                    {viewSeller?.seller
                                                      ?.businessDescription &&
                                                      Capitalize(
                                                        viewSeller?.seller
                                                          ?.businessDescription
                                                      )}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>No results found</h4>
                  <p>
                    No search matched your criteria. Try searching for something
                    else.
                  </p>
                </div>
              ) : (
                <PaginationComponent
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(Sellers, [
  "SUPER_ADMIN",
  "FINANCE",
  "MARKETPLACE_ADMIN",
  "AFCTCA",
  "OLD_MUTUAL",
  "FARMBENE",
]);
