import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../components/AppState";
import { axiosInstance } from "../../../components/baseUrl";
import PaginationComponent from "../../../components/PaginationComponent";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
// import Search from "../../../components/Search";
import Search from "../dashboardComponents/Search";
import SellersSidebar from "../dashboardComponents/SideBar";
import "./productlisting.css";
import {
  convertDateFormat,
  Capitalize,
} from "../../../helpers/hellpersFunctions";
import CardsLoader from "../../../components/skeletonLoaders/CardsLoader";
import TableLoader from "../../../components/skeletonLoaders/TableLoader";

const ProductListing = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [noMatch, setNoMatch] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 10;

  const { user, activitySummary, activityLoading, userLoading } =
    useContext(AppContext);

  const navigate = useNavigate();

  const getProducts = async () => {
    try {
      axiosInstance.get("/product").then((response) => {
        setProducts(response.data.data.products);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commentsData = useMemo(() => {
    let computedProducts = products;

    if (search) {
      computedProducts = computedProducts.filter(
        (comment) =>
          comment.productName.toLowerCase().includes(search.toLowerCase()) ||
          (comment.createdBy &&
            comment.createdBy.firstName
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          comment.productStatus.toLowerCase().includes(search.toLowerCase())
      );
      if (computedProducts.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedProducts.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedProducts.length);

    return computedProducts.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [products, currentPage, search]);

  return (
    <>
      <div>
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>All Products</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <h1 className="section-title">Activity Summary</h1>
            {activityLoading || userLoading ? (
              <CardsLoader />
            ) : (
              <div className="main-overview">
                <div className="overview-card">
                  <div>
                    <h2>Total Products</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>{activitySummary.total_number_of_products}</h3>
                    </div>
                  </div>
                </div>

                <div className="overview-card">
                  <div>
                    <h2>Total Approved Products</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {activitySummary.total_number_of_approved_products}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="overview-card">
                  <div>
                    <h2>Total Disapproved Products</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {activitySummary.total_number_of_disapproved_products}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="section-title-ctn">
              <h1 className="section-title">Latest Products</h1>
              <div className="header__search">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>

            {loading ? (
              <TableLoader />
            ) : (
              <>
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Date created</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Sellers Name</th>
                            <th scope="col">Sellers email</th>
                            <th scope="col">Sellers Phone Number</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {commentsData &&
                            commentsData.map((product, index) => {
                              return (
                                <tr key={product.id}>
                                  <td>
                                    {product.createdAt &&
                                      convertDateFormat(product.createdAt)}
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      <div className="flex-grow-1 ms-3">
                                        <p className="text-center">
                                          {product.productName}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {`${
                                      product.createdBy &&
                                      product.createdBy.firstName
                                    } ${
                                      product.createdBy &&
                                      product.createdBy.LastName
                                    }`}
                                  </td>

                                  <td>
                                    {product?.createdBy?.email &&
                                      product?.createdBy?.email}
                                  </td>
                                  <td>
                                    {product?.createdBy?.phoneNumber &&
                                      product?.createdBy?.phoneNumber}
                                  </td>
                                  <td>
                                    {product.productStatus === "PENDING" && (
                                      <div className="text-warning rounded-pill">
                                        PENDING
                                      </div>
                                    )}
                                    {product.productStatus === "APPROVED" && (
                                      <div className="text-success rounded-pill">
                                        APPROVED
                                      </div>
                                    )}
                                    {product.productStatus ===
                                      "DISAPPROVED" && (
                                      <div className="text-danger rounded-pill">
                                        DISAPPROVED
                                      </div>
                                    )}
                                  </td>

                                  <td>
                                    <Link to={`/view-product/${product.id}`}>
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {noMatch === true ? (
                  <div className="empty-state">
                    <h4>No results found</h4>
                    <p>
                      No product matched your criteria. Try searching for
                      something else.
                    </p>
                  </div>
                ) : (
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(ProductListing, [
  "SUPER_ADMIN",
  "FINANCE",
  "MARKETPLACE_ADMIN",
]);
