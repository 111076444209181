import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import Login from "./pages/Authentication/Login";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Transaction from "./pages/Dashboard/transaction/Transaction";
import EmailVerification from "./pages/Authentication/EmailVerification";
import Buyers from "./pages/Dashboard/buyer/Buyers";
import Sellers from "./pages/Dashboard/sellers/Sellers";
import SellersSubscription from "./pages/Dashboard/subscription/sellerssubscription/SellersSubscription";
import Settings from "./pages/Dashboard/settings/Settings";
import Rfqs from "./pages/Dashboard/rfq/Rfqs";
import ProductListing from "./pages/Dashboard/ProductListing/ProductListing";
import Dispute from "./pages/Dashboard/disputes/Dispute";
import ViewProduct from "./pages/Dashboard/ProductListing/ViewProduct";
import ViewRfq from "./pages/Dashboard/rfq/ViewRfq";
import CreateCategories from "./pages/Dashboard/categories/CreateCategories";
import Overview from "./pages/Dashboard/overview/Overview";
import AppState from "./components/AppState";
import SubCategory from "./pages/Dashboard/categories/SubCategory";
import EditCategory from "./pages/Dashboard/categories/EditCategory";
import Unauthorized from "./pages/Dashboard/unauthorized/Unauthorized";
import PasswordReset from "./pages/Authentication/PasswordReset";
import Users from "./pages/Dashboard/Admin_Users/Users";
import ViewTransaction from "./pages/Dashboard/transaction/ViewTransaction";
import ViewCategory from "./pages/Dashboard/categories/ViewCategory";
import ParentCategories from "./pages/Dashboard/categories/ParentCategories";
import NetworkPage from "./pages/Dashboard/Network/NetworkPage";
import ErrorPage from "./pages/Dashboard/ErrorPage/ErrorPage.jsx";
import SourcePro from "./pages/Dashboard/SourcePro/SourcePro.jsx";
import UploadSourceTransaction from "./pages/Dashboard/UploadSourceProTransaction/UploadSourceTransaction.jsx";
import Analytics from "./pages/Dashboard/Analytics/Analytics.jsx";
import AnalyticsCount from "./pages/Dashboard/AnalyticsCount/AnalyticsCount.jsx";

function App() {
  return (
    <AppState>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route exact path="product-listing" element={<ProductListing />} />
        <Route path="/verify-email/:email" element={<EmailVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/set-password/:userID/:token"
          element={<ResetPassword />}
        />
        <Route path="/passwordreset/:token" element={<PasswordReset />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/:userId/:token" element={<Login />} />

        <Route
          exact
          path="/sellers-subscription"
          element={<SellersSubscription />}
        />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/analytics-count" element={<AnalyticsCount />} />
        <Route path="/buyers" element={<Buyers />} />

        <Route path="/sellers" element={<Sellers />} />

        <Route path="/transactions" element={<Transaction />} />

        <Route path="/create-category" element={<CreateCategories />} />
        <Route path="/edit-category/:categoryId" element={<EditCategory />} />
        <Route path="/sub-category/:categoryId" element={<SubCategory />} />
        <Route path="/sub-category/" element={<SubCategory />} />
        <Route path="/parent-category" element={<ParentCategories />} />
        <Route path="/view-category/:categoryId" element={<ViewCategory />} />

        <Route exact path="/rfqs" element={<Rfqs />} />
        <Route exact path="settings" element={<Settings />} />
        <Route path="/disputes" element={<Dispute />} />

        <Route path="/view-order/:orderId" element={<ViewTransaction />} />

        <Route path="/view-product/:productId" element={<ViewProduct />} />
        <Route path="/view-rfq/:rfqId" element={<ViewRfq />} />
        <Route path="/admin-users" element={<Users />} />

        <Route path="/source-pro" element={<SourcePro />} />
        <Route
          path="/source-pro/upload-transaction"
          element={<UploadSourceTransaction />}
        />

        <Route path="/overview" element={<Overview />} />
        <Route path="/no-connection" element={<NetworkPage />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </AppState>
  );
}

export default App;
