import React, { useContext } from "react";
import { useGetTagAnalytics } from "../../../hooks/useGetTagAnalytics";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import { AppContext } from "../../../components/AppState";
import { Link } from "react-router-dom";
import { Capitalize } from "../../../helpers/hellpersFunctions";
import SellersSidebar from "../dashboardComponents/SideBar";
import PaginationComponent from "../../../components/PaginationComponent";
import TableLoader from "../../../components/skeletonLoaders/TableLoader";

const AnalyticsCount = () => {
  const { user } = useContext(AppContext);
  const {
    eventTagDataCount,
    setCurrentPage,
    loading,
    ITEMS_PER_PAGE,
    currentPage,
    totalItems,
    noMatch,
  } = useGetTagAnalytics();

  return (
    <>
      <div className="grid-container">
        <ToastContainer />
        <header className="header">
          <div className="header__message me-5">
            <h2>All Event Tag AnalyticsCount</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>

        <SellersSidebar />

        {loading ? (
          <TableLoader />
        ) : (
          <main className="main">
            <div className="section-title-ctn">
              <h1 className="section-title">Latest Products</h1>
              <div className="header__search">
                {/* <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              /> */}

                <Link
                  className="flex justify-end bg-slate-700 text-yellow-50 px-6 py-2 rounded-md hover:text-white hover:bg-slate-500 transition-all"
                  to="/analytics"
                >
                  Analytics details
                </Link>
              </div>
            </div>
            {/* <h1 className="section-title">All Orders</h1> */}

            <>
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">S/N</th>
                          <th scope="col">Event</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventTagDataCount.map((obj, index) =>
                          Object.entries(obj).map(([key, value]) => (
                            <tr key={`${index}-${key}`}>
                              <td>{index + 1}</td>
                              <td>{key}</td>
                              <td>{value}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>No results found</h4>
                  <p>
                    No search matched your criteria. Try searching for something
                    else.
                  </p>
                </div>
              ) : (
                <div className="pag-ctn">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              )}
            </>
          </main>
        )}
      </div>
    </>
  );
};

export default ProtectedRoutes(AnalyticsCount, [
  "SUPER_ADMIN",
  "MARKETPLACE_ADMIN",
]);
