import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../../Dashboard/Dashboard.css";
import "./Sidebar.css";
import { axiosInstance } from "../../../components/baseUrl";
import { AppContext } from "../../../components/AppState";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../../assets/img/logo-white.png";

const SellersSidebar = () => {
  const [isActive, setIsActive] = useState(false);

  const { user } = useContext(AppContext);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const handleLogout = async () => {
    try {
      axiosInstance.get("/auth/signout").then((response) => {
        toast.success(`${response.data.data.message}`, {
          position: "top-right",
          autoClose: 2000,
          pauseHover: true,
          draggable: true,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <i
        onClick={handleClick}
        className="fa fa-bars text-dark"
        aria-hidden="true"
        style={{ fontSize: "1.5rem" }}
      ></i>

      {user.role === "AFCTCA" ||
      user.role === "FARMBENE" ||
      user.role === "OLD_MUTUAL" ? (
        <aside id="seller" className={isActive ? "media-sidenav" : "sidenav"}>
          <div className="sidenav__close-icon" onClick={handleClick}>
            <i className="fas fa-times sidenav__brand-close sidenav__close-icon"></i>
          </div>

          <div className="d-logo-area mb-3">
            <img className="logo" src={Logo} alt="TOFA" />
          </div>

          {/* <h2 className="sidenav_title">Dashboard</h2> */}

          <ul className="sidenav__list">
            <Link className="sidenav-link" to="/overview">
              <li className="sidenav__list-item">
                <i className="fa fa-home list_icon" aria-hidden="true"></i>
                Overview
              </li>
            </Link>

            <Link className="sidenav-link" to="/sellers">
              <li className="sidenav__list-item">
                <i className="fa fa-users list_icon" aria-hidden="true"></i>
                Sellers
              </li>
            </Link>
            <Link className="sidenav-link" to="/transactions">
              <li className="sidenav__list-item">
                <i
                  className="fa fa-shopping-bag list_icon"
                  aria-hidden="true"
                ></i>
                Transactions
              </li>
            </Link>
          </ul>
          <ul className="sidenav__list mt-auto">
            <Link to="/" className="sidenav-link" onClick={handleLogout}>
              <li className="sidenav__list-item">
                <i className="fa fa-sign-out list_icon" aria-hidden="true"></i>
                {user ? "Logout" : "Login"}
              </li>
            </Link>
          </ul>
        </aside>
      ) : (
        <aside id="seller" className={isActive ? "media-sidenav" : "sidenav"}>
          <div className="sidenav__close-icon" onClick={handleClick}>
            <i className="fas fa-times sidenav__brand-close sidenav__close-icon"></i>
          </div>

          <div className="d-logo-area mb-3">
            <img className="logo" src={Logo} alt="TOFA" />
          </div>

          {/* <h2 className="sidenav_title">Dashboard</h2> */}

          <ul className="sidenav__list">
            <Link className="sidenav-link" to="/overview">
              <li className="sidenav__list-item">
                <i className="fa fa-home list_icon" aria-hidden="true"></i>
                Overview
              </li>
            </Link>

            <Link className="sidenav-link" to="/Analytics-count">
              <li className="sidenav__list-item">
                <i className="fa fa-th-large list_icon" aria-hidden="true"></i>
                Analytics
              </li>
            </Link>

            <Link className="sidenav-link" to="/buyers">
              <li className="sidenav__list-item">
                <i
                  className="fa fa-shopping-cart list_icon"
                  aria-hidden="true"
                ></i>
                Buyers
              </li>
            </Link>

            <Link className="sidenav-link" to="/sellers">
              <li className="sidenav__list-item">
                <i className="fa fa-users list_icon" aria-hidden="true"></i>
                Sellers
              </li>
            </Link>
            <Link className="sidenav-link" to="/transactions">
              <li className="sidenav__list-item">
                <i
                  className="fa fa-shopping-bag list_icon"
                  aria-hidden="true"
                ></i>
                Transactions
              </li>
            </Link>
            <Link className="sidenav-link" to="/parent-category">
              <li className="sidenav__list-item">
                <i className="fa fa-sliders list_icon" aria-hidden="true"></i>
                Category
              </li>
            </Link>

            <Link className="sidenav-link" to="/product-listing">
              <li className="sidenav__list-item">
                <i className="fa fa-th-large list_icon" aria-hidden="true"></i>
                Products
              </li>
            </Link>
            <Link className="sidenav-link" to="/sellers-subscription">
              <li className="sidenav__list-item">
                <i className="fa fa-star-o list_icon" aria-hidden="true"></i>
                Subscriptions
              </li>
            </Link>

            <Link className="sidenav-link" to="/rfqs">
              <li className="sidenav__list-item">
                <i className="fa fa-file-text list_icon" aria-hidden="true"></i>
                RFQs
              </li>
            </Link>
            <Link className="sidenav-link" to="/disputes">
              <li className="sidenav__list-item">
                <i
                  className="fa fa-commenting-o list_icon"
                  aria-hidden="true"
                ></i>
                Disputes
              </li>
            </Link>

            <Link className="sidenav-link" to="/admin-users">
              <li className="sidenav__list-item">
                <i className="fa fa-user list_icon" aria-hidden="true"></i>
                Admin Users
              </li>
            </Link>
            <Link className="sidenav-link" to="/source-pro">
              <li className="sidenav__list-item">
                <i className="fa fa-sliders list_icon" aria-hidden="true"></i>
                SourcePro
              </li>
            </Link>
          </ul>
          <ul className="sidenav__list mt-auto">
            <Link to="/" className="sidenav-link" onClick={handleLogout}>
              <li className="sidenav__list-item">
                <i className="fa fa-sign-out list_icon" aria-hidden="true"></i>
                {user ? "Logout" : "Login"}
              </li>
            </Link>
          </ul>
        </aside>
      )}
    </>
  );
};

export default SellersSidebar;
