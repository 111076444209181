import React from "react";
import { Skeleton } from "antd";

const OverviewLoader = () => {
  return (
    <div className="main-overview category_home_page">
      <div className="overview-card">
        <div className="h-24 min-h-24 max-h-24">
          <Skeleton.Input active />
        </div>
      </div>
      <div className="overview-card">
        <div className="h-24 min-h-24 max-h-24">
          <Skeleton.Input active />
        </div>
      </div>
      <div className="overview-card">
        <div className="h-24 min-h-24 max-h-24">
          <Skeleton.Input active />
        </div>
      </div>
      <div className="overview-card">
        <div className="h-24 min-h-24 max-h-24">
          <Skeleton.Input active />
        </div>
      </div>
      <div className="overview-card">
        <div className="h-24 min-h-24 max-h-24">
          <Skeleton.Input active />
        </div>
      </div>
      <div className="overview-card">
        <div className="h-24 min-h-24 max-h-24">
          <Skeleton.Input active />
        </div>
      </div>
    </div>
  );
};

export default OverviewLoader;
