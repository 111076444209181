import { useState, useEffect, useMemo } from "react";
import { axiosInstance } from "../components/baseUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useGetEventTagCount = () => {
  const [analyticsCount, setAnalyticsCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [noMatch, setNoMatch] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 10;

  const getAllEventCount = async () => {
    setLoading(true);
    try {
      const data = await axiosInstance.get(`/event-tagging/getAllEventTags`);
      setAnalyticsCount(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 6000,
        pauseHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    getAllEventCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventTagData = useMemo(() => {
    let computedAnalytics = analyticsCount;

    if (search) {
      computedAnalytics = computedAnalytics.filter(
        (analyticsCount) =>
          (analyticsCount?.userName &&
            analyticsCount?.userName
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (analyticsCount?.tag &&
            analyticsCount?.tag.includes(search.toLowerCase())) ||
          (analyticsCount?.category &&
            analyticsCount?.category
              .toLowerCase()
              .includes(search.toLowerCase()))
      );
      if (computedAnalytics.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedAnalytics.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedAnalytics.length);

    return computedAnalytics.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [analyticsCount, currentPage, search]);
  return {
    eventTagData,
    setCurrentPage,
    setSearch,
    loading,
    ITEMS_PER_PAGE,
    currentPage,
    totalItems,
    noMatch,
  };
};
