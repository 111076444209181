import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../components/AppState";
import { axiosInstance } from "../../../components/baseUrl";
import PaginationComponent from "../../../components/PaginationComponent";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import Search from "../dashboardComponents/Search";
import SellersSidebar from "../dashboardComponents/SideBar";

const Rfqs = () => {
  const [rfqs, setRfqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [noMatch, setNoMatch] = useState(false);
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotalItems] = useState(0);

  const { user, activitySummary, activityLoading, userLoading } =
    useContext(AppContext);

  const navigate = useNavigate();

  const convertDateFormat = (oldDate) => {
    let date = new Date(oldDate).toString().split(" ");
    let newFormat = `${date[0]} ${date[2]}  ${date[1]}, ${date[3]}`;
    return newFormat;
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const rfqData = useMemo(() => {
    let computedRfqs = rfqs;

    if (search) {
      computedRfqs = computedRfqs.filter(
        (comment) =>
          comment.status.toLowerCase().includes(search.toLowerCase()) ||
          comment.productName.toLowerCase().includes(search.toLowerCase()) ||
          comment.termsOfTrade.toLowerCase().includes(search.toLowerCase())
      );
      if (computedRfqs.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedRfqs.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedRfqs.length);

    //currentPage Slice

    return computedRfqs.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [rfqs, currentPage, search]);

  const getRfqs = async () => {
    try {
      axiosInstance.get("/rfq/all").then((response) => {
        console.log("response", response);
        setRfqs(response.data.data.rfqs);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
    }
  };

  useEffect(() => {
    getRfqs();
  }, []);

  if (loading || userLoading || activityLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>RFQ's</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <h1 className="section-title">Activity Summary</h1>
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <h2>Total RFQs</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>{activitySummary.total_number_of_enquiries}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Open RFQs</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>{activitySummary.total_awaiting_response_quote}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Processed RFQs</h2>
                  {/* <p>Detailed transaction history is on the order page</p> */}
                  <div className="d-flex justify-content-between mt-4">
                    <h3>{activitySummary.total_responded_quote}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-title-ctn">
              <h1 className="section-title">Latest Orders</h1>
              <div className="header__search">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>

            <div className="main-overview">
              <div className="overview-card no-padding">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date created</th>
                        <th scope="col">Product Info</th>
                        <th scope="col">Product Cost</th>
                        <th scope="col">Shipping Terms</th>
                        <th scope="col">Payment Terms</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rfqData &&
                        rfqData.map((rfq) => {
                          return (
                            <tr key={rfq.id}>
                              <td>
                                {rfq.createdAt &&
                                  convertDateFormat(rfq.createdAt)}
                              </td>
                              <td>{rfq.productName}</td>
                              <td>USD {rfq.targetPrice}</td>
                              <td>{rfq.termsOfTrade}</td>
                              <td>{rfq.paymentTerms}</td>
                              <td>
                                <div
                                  className={
                                    rfq.status === "AWAITING_RESPONSE"
                                      ? "text-danger"
                                      : "text-primary"
                                  }
                                >
                                  {rfq.status}
                                </div>
                              </td>
                              <td>
                                <Link to={`/view-rfq/${rfq.id}`}>View</Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {noMatch === true ? (
              <div className="empty-state">
                <h4>No results found</h4>
                <p>
                  No search matched your criteria. Try searching for something
                  else.
                </p>
              </div>
            ) : (
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(Rfqs, [
  "SUPER_ADMIN",
  "SOURCE_PRO_ADMIN",
  "FINANCE",
  "MARKETPLACE_ADMIN",
]);
