import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Dashboard.css";
import SellersSidebar from "../dashboardComponents/SideBar";
import { axiosInstance } from "../../../components/baseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AppContext } from "../../../components/AppState";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ParentCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const { activitySummary, userLoading, user, activityLoading } =
    useContext(AppContext);

  const navigate = useNavigate();

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getCategory = async () => {
    try {
      axiosInstance.get("/category").then((response) => {
        setCategories(response.data.data.categories);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleDelete = async (categoryId) => {
    try {
      await axiosInstance.delete(`/category/${categoryId}`).then((response) => {
        toast.success("Successfully Deleted Category", {
          position: "top-right",
          autoClose: 2000,
          pauseHover: true,
          draggable: true,
        });
      });
      setTimeout(() => {
        navigate("/parent-category");
      }, 2000);
    } catch (error) {
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 4000,
        pauseHover: true,
        draggable: true,
      });
    }
  };

  const submit = (categoryId) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleDelete(categoryId),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  if (loading || activityLoading || userLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="grid-container">
        <ToastContainer />
        <header className="header">
          <div className="header__message me-5">
            <h2>Categories</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>

        <SellersSidebar />

        <main className="main">
          <h1 className="section-title">Activity Summary</h1>
          <div className="main-overview">
            <div className="overview-card">
              <div>
                <h2>All Categories</h2>
                <div className="d-flex justify-content-between mt-4">
                  <h3>{activitySummary.total_parent_categories}</h3>
                </div>
              </div>
            </div>
            <div className="overview-card">
              <div>
                <h2>Total Sub Categories</h2>
                <div className="d-flex justify-content-between mt-4">
                  <h3>{activitySummary.total_children_categories}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <h1 className="section-title">All Parent Categories</h1>
            </div>
            <div className="col-lg-6 mb-2" align="right">
              <Link to="/create-category">
                <button className="btn btn-dark"> Create Category</button>
              </Link>
            </div>
          </div>
          <div className="main-overview">
            <div className="overview-card no-padding">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col" className="text-center">
                        Category Name
                      </th>
                      {/* <th className="text-center">Link</th> */}
                      <th className="text-center">Slug</th>

                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories &&
                      categories.map((category, index) => {
                        return (
                          <tr key={category.id}>
                            <td>{index + 1}</td>

                            <td className="text-center">{category.category}</td>
                            <td className="text-center">{category.slug}</td>

                            {/* <td className="text-center">
                              <div className="sub-category-role">
                                <Link
                                  to={`/sub-category/${category.id}`}
                                  disabled={true}
                                >
                                  create sub-category
                                </Link>
                              </div>
                            </td> */}
                            <td className="text-center">
                              <div className="nav-item dropdown">
                                <Link
                                  className="nav-link main-nav-link "
                                  align="center"
                                  to="#"
                                  id="navbarDropdown"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fa fa-chevron-down"
                                    align="right"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <ul
                                  className="dropdown-menu animate slideIn category-dropdown-item"
                                  aria-labelledby="navbarDropdown"
                                  style={{
                                    width: "50px !important",
                                    textAlign: "center",
                                  }}
                                >
                                  <li>
                                    <a href={`/view-category/${category.id}`}>
                                      View
                                    </a>
                                  </li>
                                  <li>
                                    <Link
                                      to={`/edit-category/${category.id}`}
                                      className=" dropdown-item"
                                    >
                                      Edit
                                    </Link>
                                  </li>
                                  <li>
                                    <div
                                      className="dropdown-item text-danger"
                                      onClick={(e) => submit(category.id)}
                                    >
                                      Delete
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProtectedRoutes(ParentCategories);
