import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SellersSidebar from "../dashboardComponents/SideBar";
// import peer from "../../../assets/img/pear.png";
import { axiosInstance } from "../../../components/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

const ViewProduct = () => {
  const [viewProduct, setViewProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState();
  const [productImage, setProductImage] = useState([]);
  const [productSpecification, setProductSpecification] = useState([]);
  const [approveLoader, setApproveLoader] = useState(false);
  const [declineLoader, setDeclineLoader] = useState(false);
  const { productId } = useParams();

  const navigate = useNavigate();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const showProduct = () => {
    setLoading(true);
    axiosInstance
      .get(`/product/${productId}`)
      .then((response) => {
        setViewProduct(response.data.data);
        const arr = Object.entries(response.data.data.productSpecification);
        setProductSpecification(arr);
        setProductImage(response.data.data.productImages);
        if (response.data.data.productImages[0]) {
          setCurrentImage(response.data.data.productImages[0].image);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
      });
  };

  const displayImageHandler = (imageIndex) => {
    setCurrentImage(productImage[imageIndex].image);
  };

  useEffect(() => {
    showProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const UpdateProduct = async (status) => {
    try {
      const currentStatus = status;
      if (currentStatus === "APPROVED") {
        setApproveLoader(true);
      } else if (currentStatus === "DISAPPROVED") {
        setDeclineLoader(true);
      }
      await axiosInstance.patch("/product/product-status", {
        productStatus: currentStatus,
        productId,
      });
      setDeclineLoader(false);
      setApproveLoader(false);
      if (currentStatus === "APPROVED") {
        toast.success("Product has been approved", {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
      } else if (currentStatus === "DISAPPROVED") {
        toast.success("Product has been disapproved", {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 4500);
    } catch (error) {
      setDeclineLoader(false);
      setApproveLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 6000,
        pauseHover: true,
        draggable: true,
      });

      console.log(error);
    }
  };

  if (loading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <ToastContainer />
        <div className="grid-container">
          <header className="header">
            <div className="header__message">
              <h2> {viewProduct.productName && viewProduct.productName}</h2>
            </div>
          </header>
          <SellersSidebar />
          <main className="main">
            <div
              className="d-flex flex-end my-3"
              style={{ justifyContent: "right" }}
            >
              {viewProduct.productStatus === "DISAPPROVED" ? (
                <>
                  {approveLoader ? (
                    <button type="submit" className="btn btn-dark px-5 mx-2">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark mx-2 px-5"
                      onClick={(e) => UpdateProduct("APPROVED")}
                    >
                      Approve product
                    </button>
                  )}
                </>
              ) : viewProduct.productStatus === "APPROVED" ? (
                <>
                  {declineLoader ? (
                    <button type="submit" className="btn btn-dark px-5">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning px-5"
                      onClick={(e) => UpdateProduct("DISAPPROVED")}
                    >
                      Decline product
                    </button>
                  )}
                </>
              ) : (
                <>
                  {approveLoader ? (
                    <button type="submit" className="btn btn-dark px-5 mx-2">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark mx-2 px-5"
                      onClick={(e) => UpdateProduct("APPROVED")}
                    >
                      Approve product
                    </button>
                  )}
                  {declineLoader ? (
                    <button type="submit" className="btn btn-dark px-5">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning px-5"
                      onClick={(e) => UpdateProduct("DISAPPROVED")}
                    >
                      Decline product
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="main-overview">
              <div className="overview-card">
                <div className="row my-4 main-cont">
                  <div className="col-12 col-lg-3 mb-5">
                    <div>
                      <h6>Product Image</h6>
                    </div>

                    <div className="product-images">
                      <img
                        src={currentImage}
                        className="main-image"
                        alt="peer"
                      />
                      <div className="d-flex mt-2 ">
                        {viewProduct.productImages &&
                          viewProduct.productImages.map((image, index) => {
                            return (
                              <div
                                className="box mx-1"
                                key={image.id}
                                onClick={(e) => displayImageHandler(index)}
                              >
                                <img
                                  src={image.image}
                                  alt="box1"
                                  style={{
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <h6>Product Information</h6>
                  </div> */}
                  <div className="col-12 col-lg-4 mb-3">
                    <div>
                      <h6>Product Information</h6>
                    </div>
                    <div className="product-detailss">
                      <div className="product-infoo">
                        <div className="detailss">
                          <div className="title">First Name:</div>
                          <p className="spec ">
                            {" "}
                            {viewProduct.createdBy &&
                              viewProduct.createdBy.firstName}{" "}
                          </p>
                        </div>
                        <div className="detailss">
                          <div className="title">Last Name:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.createdBy &&
                              viewProduct.createdBy.LastName}
                          </p>
                        </div>

                        <div className="detailss">
                          <div className="title">MOQ:</div>
                          <p className="spec">
                            1{" "}
                            {viewProduct.unitForMinOrder &&
                              viewProduct.unitForMinOrder}
                          </p>
                        </div>
                        <div className="detailss">
                          <div className="title">Country of Origin:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.countryOfOrigin &&
                              viewProduct.countryOfOrigin}
                          </p>
                        </div>
                        <div className="detailss">
                          <div className="title">Min delivery duration:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.minDuration && viewProduct.minDuration}
                          </p>
                        </div>
                        <div className="detailss">
                          <div className="title">Max delivery duration:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.maxDuration && viewProduct.maxDuration}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <h6>Product Information</h6>
                  </div> */}
                  <div className="col-12 col-lg-4 ">
                    <div>
                      <h6>Available Specification</h6>
                    </div>
                    <div className="product-detailss">
                      <div className="product-infoo">
                        {productSpecification &&
                          productSpecification.map((specification, index) => (
                            <div className="detailss" key={index}>
                              <div className="title">{specification[0]}: </div>
                              <p className="spec">{specification[1]}</p>
                            </div>
                          ))}
                        <div className="detailss">
                          <div className="title">Supply Capacity:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.supplyCapacity &&
                              numberWithCommas(viewProduct.supplyCapacity)}{" "}
                            {viewProduct.unitForSupplyCapacity &&
                              viewProduct.unitForSupplyCapacity}
                          </p>
                        </div>

                        <div className="detailss">
                          <div className="title">Created At:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.createdAt &&
                              dayjs(viewProduct.createdAt).format(
                                "D MMMM YYYY"
                              )}
                          </p>
                        </div>

                        <div className="detailss ">
                          <div className="title">Product Status:</div>
                          <div className="spec ">
                            {" "}
                            {viewProduct.productStatus &&
                              viewProduct.productStatus === "PENDING" && (
                                <div className="text-warning rounded-pill">
                                  PENDING
                                </div>
                              )}
                            {viewProduct.productStatus &&
                              viewProduct.productStatus === "APPROVED" && (
                                <div className="text-success rounded-pill">
                                  APPROVED
                                </div>
                              )}
                            {viewProduct.productStatus &&
                              viewProduct.productStatus === "DISAPPROVED" && (
                                <div className="text-danger rounded-pill">
                                  DECLINED
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="detailss">
                          <div className="title">Category:</div>
                          <p className="spec">
                            {" "}
                            {viewProduct.Category &&
                              viewProduct.Category.category}
                          </p>
                        </div>
                        <div className="detailss">
                          <div className="title">Price Per Unit:</div>
                          <p className="spec">
                            USD{" "}
                            {viewProduct.minPricePerUnit &&
                            viewProduct.minPricePerUnit > 0
                              ? numberWithCommas(viewProduct.minPricePerUnit)
                              : viewProduct.maxPricePerUnit}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-5">
                  <div className="description-border">
                    <h6>Product Decsription</h6>
                  </div>
                  <div className="my-3">
                    <p>
                      {viewProduct.productDescription &&
                        viewProduct.productDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
          );
        </div>
      </div>
    </>
  );
};

export default ViewProduct;
