import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../components/AppState";
import { axiosInstance } from "../../../components/baseUrl";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import SellersSidebar from "../dashboardComponents/SideBar";
import "../Dashboard.css";
import OverviewLoader from "../../../components/skeletonLoaders/OverviewLoader";

import { Capitalize } from "../../../helpers/hellpersFunctions";

const Overview = () => {
  const { user } = useContext(AppContext);

  const [activitySummary, setActivitySummary] = useState();
  const [loading, setLoading] = useState(true);

  const getActivitySummary = async () => {
    try {
      if (
        user?.role === "SUPER_ADMIN" ||
        user?.role === "MARKETPLACE_ADMIN" ||
        user?.role === "FINANCE" ||
        user?.role === "SOURCE_PRO_ADMIN"
      ) {
        await axiosInstance
          .get("/dashboard/admin/activity-summary")
          .then((response) => {
            setActivitySummary(response.data.data);
            setLoading(false);
          });
      } else {
        await axiosInstance
          .post("/dashboard/admin/seller-activity-summary", {
            hearAboutUs: user?.role,
          })
          .then((response) => {
            setActivitySummary(response.data.data);
            setLoading(false);
          });
      }
      // if (user.role === "OLD_MUTUAL") {
      //   await axiosInstance
      //     .post("/dashboard/admin/seller-activity-summary", {
      //       hearAboutUs: "OLD_MUTUAL",
      //     })
      //     .then((response) => {
      //       setActivitySummary(response.data.data);
      //       console.log("response.data.data", response.data.data);
      //       setLoading(false);
      //     });
      // }
      // if (user.role === "AFCTCA") {
      //   await axiosInstance
      //     .post("/dashboard/admin/seller-activity-summary", {
      //       hearAboutUs: "AFCTCA",
      //     })
      //     .then((response) => {
      //       setActivitySummary(response.data.data);
      //       setLoading(false);
      //     });
      // } else {
      // }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getActivitySummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div className="grid-container">
          <header className="header">
            <div className="header__message me-5">
              <h2>Account Overview</h2>
            </div>

            <div className="user-area ms-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p className="">
                      {Capitalize(user.firstName).slice(0, 15)}...
                    </p>
                  ) : (
                    <p className="">
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </header>

          <SellersSidebar />

          <main className="main">
            <h1 className="section-title">Activity Summary</h1>

            {loading ? (
              <OverviewLoader />
            ) : (
              <>
                <div className="main-overview">
                  <div className="overview-card">
                    <div>
                      <h2>Total Products</h2>
                      <p>Detailed history is on the Product page</p>
                      <div className="d-flex justify-content-between mt-4">
                        <h3>
                          {user.role === "SUPER_ADMIN" || "MARKETPLACE_ADMIN"
                            ? activitySummary &&
                              activitySummary.total_number_of_products
                            : "0"}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="overview-card">
                    <div>
                      <h2>Total Transaction Revenue</h2>
                      <p>Detailed history is on the Order page</p>

                      <div className="d-flex justify-content-between mt-4">
                        <h3>
                          USD{" "}
                          {activitySummary &&
                            activitySummary.total_transactions_revenue}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="overview-card">
                    <div>
                      <h2>Total Orders</h2>
                      <p>Detailed history is on the Order page</p>
                      <div className="d-flex justify-content-between mt-4">
                        <h3>
                          {activitySummary &&
                            activitySummary.total_confirmed_orders +
                              activitySummary.total_delivered_orders +
                              activitySummary.total_pending_orders +
                              activitySummary.total_shipped_orders}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-overview">
                  <div className="overview-card">
                    {user.role === "OLD_MUTUAL" ? (
                      <div>
                        <h2>Total Delivered Orders</h2>
                        <p>Detailed history is on the Order's page</p>
                        <div className="d-flex justify-content-between mt-4">
                          <h3>
                            {activitySummary &&
                              activitySummary.total_delivered_orders}
                          </h3>
                        </div>
                      </div>
                    ) : user.role === "AFCTCA" ? (
                      <div>
                        <h2>Total Delivered Orders</h2>
                        <p>Detailed history is on the Order's page</p>
                        <div className="d-flex justify-content-between mt-4">
                          <h3>
                            {activitySummary &&
                              activitySummary.total_delivered_orders}
                          </h3>
                        </div>
                      </div>
                    ) : user.role === "FARMBENE" ? (
                      <div>
                        <h2>Total Delivered Orders</h2>
                        <p>Detailed history is on the Order's page</p>
                        <div className="d-flex justify-content-between mt-4">
                          <h3>
                            {activitySummary &&
                              activitySummary.total_delivered_orders}
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h2>Total Buyers</h2>
                        <p>Detailed history is on the Buyer's page</p>
                        <div className="d-flex justify-content-between mt-4">
                          <h3>
                            {activitySummary && activitySummary.total_buyers}
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="overview-card">
                    <div>
                      <h2>Total Sellers</h2>
                      <p>Detailed history is on the Seller's page</p>
                      <div className="d-flex justify-content-between mt-4">
                        <h3>
                          {activitySummary && activitySummary.total_sellers}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="overview-card">
                    <div>
                      <h2>Total RFQs</h2>
                      <p>Detailed history is on the RFQ page</p>
                      <div className="d-flex justify-content-between mt-4">
                        <h3>
                          {activitySummary &&
                            activitySummary.total_number_of_enquiries}
                          {user.role === "OLD_MUTUAL" &&
                            activitySummary &&
                            activitySummary.total_rfqs}
                          {user.role === "AFCTCA" &&
                            activitySummary &&
                            activitySummary.total_rfqs}
                          {user.role === "FARMBENE" &&
                            activitySummary &&
                            activitySummary.total_rfqs}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(Overview);
