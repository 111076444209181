import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import SellersSidebar from "../dashboardComponents/SideBar";
import Search from "../dashboardComponents/Search";
import { axiosInstance } from "../../../components/baseUrl";
import PaginationComponent from "../../../components/PaginationComponent";
import { AppContext } from "../../../components/AppState";
import "./buyer.css";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import CardsLoader from "../../../components/skeletonLoaders/CardsLoader";
import TableLoader from "../../../components/skeletonLoaders/TableLoader";

const Buyers = () => {
  const [buyers, setBuyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityLoader, setActivityLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [viewBuyer, setViewBuyer] = useState([]);
  const [viewLoader, setViewLoader] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [viewDashboardLoader, setViewDashboardLoader] = useState(false);
  const [summary, setSummary] = useState("");
  const [viewSummary, setViewSummary] = useState("");

  const { user, activitySummary, userLoading } = useContext(AppContext);

  const navigate = useNavigate();

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const convertDateFormat = (oldDate) => {
    let date = new Date(oldDate).toString().split(" ");
    let newFormat = `${date[2]}  ${date[1]}, ${date[3]}`;
    return newFormat;
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const commentsData = useMemo(() => {
    let computedBuyers = buyers;

    if (search) {
      computedBuyers = computedBuyers.filter(
        (comment) =>
          comment.LastName.toLowerCase().includes(search.toLowerCase()) ||
          comment.firstName.toLowerCase().includes(search.toLowerCase())
      );
      if (computedBuyers.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedBuyers.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedBuyers.length);

    //currentPage Slice

    return computedBuyers.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [buyers, currentPage, search]);

  const getBuyers = async () => {
    try {
      axiosInstance.get("/auth/users?role=BUYER").then((response) => {
        setBuyers(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    getBuyers();
  }, []);

  const getSummary = async () => {
    try {
      await axiosInstance
        .get("/dashboard/admin/buyer-activity-summary")
        .then((response) => {
          setSummary(response.data.data);
          setActivityLoader(false);
        });
    } catch (error) {
      console.log(error);
      setActivityLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
    }
  };
  useEffect(() => {
    getSummary();
  }, []);

  const showBuyer = async (buyerID) => {
    try {
      setViewLoader(true);
      setViewDashboardLoader(true);
      await axiosInstance
        .get(`/auth/users/user/${buyerID}`)
        .then((response) => {
          setViewBuyer(response.data.data);
          setViewLoader(false);
        });
      await axiosInstance
        .get(`/dashboard/buyer/${buyerID}`)
        .then((response) => {
          setViewSummary(response.data.data);
          setViewDashboardLoader(false);
        });
    } catch (error) {
      console.log(error);
      setViewLoader(false);
      setViewDashboardLoader(false);
    }
  };

  // if (loading || activityLoader || userLoading) {
  //   return (
  //     <div className="lds-ctn">
  //       <div className="lds-ripple">
  //         <div></div>
  //         <div></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="grid-container">
        <header className="header">
          <div className="header__message me-5">
            <h2>All Buyers</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>
        <SellersSidebar />

        <main className="main">
          <h1 className="section-title">Activity Summary</h1>
          {activityLoader ? (
            <CardsLoader />
          ) : (
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <h2>Total Buyers</h2>

                  <div className="d-flex justify-content-between mt-4">
                    <h3>{activitySummary.total_buyers}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Total RFQ</h2>
                  <p>Detailed history is on the RFQ page</p>
                  <div className="d-flex justify-content-between mt-4">
                    <h3>{summary.total_number_of_enquiries}</h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>Total Orders</h2>
                  <p>Detailed history is on the Order page</p>
                  <div className="d-flex justify-content-between mt-4">
                    <h3>{summary.total_number_of_orders}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="section-title-ctn">
            <h1 className="section-title">All buyers</h1>
            <div className="header__search">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>

          {loading ? (
            <TableLoader />
          ) : (
            <>
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">S/N</th>
                          <th scope="col">Full Name</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Email</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commentsData &&
                          commentsData.map((buyer, index) => {
                            return (
                              <tr key={buyer.id}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <p>
                                        {buyer.firstName} {buyer.LastName}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{buyer.phoneNumber}</td>

                                <td>{buyer.email}</td>
                                <td>
                                  <Link
                                    to="/n"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={(e) => showBuyer(buyer.id)}
                                  >
                                    View
                                  </Link>

                                  <div
                                    className="modal fade"
                                    id="exampleModal"
                                    tabIndex="-1"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div
                                      className="modal-dialog modal-lg"
                                      style={{
                                        backgroundColor: "#F5F5F5",
                                      }}
                                    >
                                      <div
                                        className="modal-content"
                                        style={{
                                          backgroundColor: "#F5F5F5",
                                        }}
                                      >
                                        <div className="modal-header">
                                          <h5
                                            className="modal-title"
                                            id="exampleModalLabel"
                                          >
                                            Buyers Information
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                        {viewLoader ? (
                                          <div className="lds-ctnn">
                                            <div className="lds-ellipsis">
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="modal-body">
                                              <div className="row">
                                                <div className="col-lg-6 col-md-10 col-12">
                                                  <div className="overview-card">
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        First Name:
                                                      </p>
                                                      <p className="px-1">
                                                        {" "}
                                                        {viewBuyer?.firstName &&
                                                          Capitalize(
                                                            viewBuyer?.firstName
                                                          )}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Last Name:
                                                      </p>
                                                      <p className="px-1">
                                                        {" "}
                                                        {viewBuyer?.LastName &&
                                                          Capitalize(
                                                            viewBuyer?.LastName
                                                          )}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Phone number:
                                                      </p>
                                                      <p className="px-1">
                                                        {viewBuyer.phoneNumber &&
                                                          viewBuyer?.phoneNumber}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Email:
                                                      </p>
                                                      <p className="px-1">
                                                        {viewBuyer?.email &&
                                                          Capitalize(
                                                            viewBuyer?.email
                                                          )}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Business Name:
                                                      </p>
                                                      <p className="px-1">
                                                        {viewBuyer.businessName &&
                                                          Capitalize(
                                                            viewBuyer?.businessName
                                                          )}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Business Address:
                                                      </p>
                                                      <p className="px-1">
                                                        {viewBuyer.address &&
                                                          Capitalize(
                                                            viewBuyer?.address
                                                          )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                {viewDashboardLoader ? (
                                                  <div className="col-lg-5 col-sm-6 col-12">
                                                    <div className="lds-ctnn">
                                                      <div className="lds-ellipsis">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="col-lg-6 col-md-10 col-12">
                                                    <div className="overview-card">
                                                      <div className="d-flex my-3">
                                                        <p className="bold">
                                                          Total Enquiries:{" "}
                                                        </p>
                                                        <p className="px-1">
                                                          {viewSummary.total_number_of_enquiries &&
                                                            numberWithCommas(
                                                              viewSummary.total_number_of_enquiries
                                                            )}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex my-3">
                                                        <p className="bold">
                                                          Total Orders:{" "}
                                                        </p>
                                                        <p className="px-1">
                                                          {viewSummary &&
                                                            viewSummary.total_pending_orders +
                                                              viewSummary.total_shipped_orders +
                                                              viewSummary.total_delivered_orders +
                                                              viewSummary.total_confirmed_orders}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex my-3">
                                                        <p className="bold">
                                                          Total Pending Orders:
                                                        </p>
                                                        <p className="px-1">
                                                          {viewSummary.total_pending_orders &&
                                                            numberWithCommas(
                                                              viewSummary.total_pending_orders
                                                            )}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex my-3">
                                                        <p className="bold">
                                                          Total Confirmed Order:
                                                        </p>
                                                        <p className="px-1">
                                                          {viewSummary.total_confirmed_orders &&
                                                            numberWithCommas(
                                                              viewSummary.total_confirmed_orders
                                                            )}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex my-3">
                                                        <p className="bold">
                                                          Total Shipped Orders:
                                                        </p>
                                                        <p className="px-1">
                                                          {viewSummary.total_shipped_orders &&
                                                            numberWithCommas(
                                                              viewSummary.total_shipped_orders
                                                            )}
                                                        </p>
                                                      </div>
                                                      <div className="d-flex my-3">
                                                        <p className="bold">
                                                          Total Delivered Order:
                                                        </p>
                                                        <p className="px-1">
                                                          {viewSummary.total_delivered_orders &&
                                                            numberWithCommas(
                                                              viewSummary.total_delivered_orders
                                                            )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                <div className="col-lg-6 col-md-10 col-12">
                                                  <div className="overview-card">
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Role:
                                                      </p>
                                                      <p className="px-1">
                                                        {" "}
                                                        {viewBuyer?.role &&
                                                          Capitalize(
                                                            viewBuyer?.role
                                                          )}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Is phone no verified:
                                                      </p>
                                                      <p className="px-1">
                                                        {" "}
                                                        {viewBuyer?.isPhoneVerified
                                                          ? "True"
                                                          : "False"}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Created account:
                                                      </p>
                                                      <p className="px-1">
                                                        {viewBuyer?.createdAt &&
                                                          convertDateFormat(
                                                            viewBuyer?.createdAt
                                                          )}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex my-3">
                                                      <p className="bold">
                                                        Updated account
                                                      </p>
                                                      <p className="px-1">
                                                        {viewBuyer?.updatedAt &&
                                                          convertDateFormat(
                                                            viewBuyer?.updatedAt
                                                          )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        <div className="modal-footer">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>No results found</h4>
                  <p>
                    No search matched your criteria. Try searching for something
                    else.
                  </p>
                </div>
              ) : (
                <PaginationComponent
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(Buyers, [
  "SOURCE_PRO_ADMIN",
  "SUPER_ADMIN",
  "FINANCE",
  "MARKETPLACE_ADMIN",
]);
