import { useState } from "react";
import { axiosInstance } from "../components/baseUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const usePostSourceProTransaction = () => {
  const [sourceProDetails, setSourceProDetails] = useState({
    productName: "",
    companyName: "",
    status: "",
    amount: "",
    quantity: "",
    type: "",
    location: "",
    currency: "",
    exChangeRate: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChnage = (e) => {
    setSourceProDetails({
      ...sourceProDetails,
      [e.target.name]: e.target.value,
    });
  };

  function convertPriceToDollars(amount, exchangeRate) {
    // Calculate the price
    let price = amount / exchangeRate;

    return parseFloat(price);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const transactionData = {
        productName: sourceProDetails.productName,
        companyName: sourceProDetails.companyName,
        status: sourceProDetails.status,
        amount: convertPriceToDollars(
          sourceProDetails.amount,
          sourceProDetails.exChangeRate
        ),
        quantity: parseFloat(sourceProDetails.quantity),
        type: sourceProDetails.type,
        location: sourceProDetails.location,
      };
      await axiosInstance.post("/sourcepro-transaction", transactionData);
      toast.success("Transaction uploaded successfully", {
        position: "top-right",
        autoClose: 4000,
        pauseHover: true,
        draggable: true,
      });

      setTimeout(() => {
        navigate("/source-pro");
      }, 2500);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 6000,
        pauseHover: true,
        draggable: true,
      });

      console.log(error);
    }
  };

  return {
    sourceProDetails,
    loading,
    handleChnage,
    handleSubmit,
  };
};
