import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { Iconly } from "react-iconly";
import "../Dashboard.css";
import SellersSidebar from "../dashboardComponents/SideBar";
import { axiosInstance } from "../../../components/baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { AppContext } from "../../../components/AppState";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

const AllSubCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [viewCategory, setViewCategory] = useState([]);
  const { categoryId } = useParams();

  const { activitySummary, user, userLoading, activityLoading } =
    useContext(AppContext);
  const navigate = useNavigate();

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDelete = async (categoryId) => {
    try {
      await axiosInstance.delete(`/category/${categoryId}`).then((response) => {
        toast.success("Successfully Deleted Category", {
          position: "top-right",
          autoClose: 2000,
          pauseHover: true,
          draggable: true,
        });
      });
      setTimeout(() => {
        navigate("/parent-category");
      }, 2000);
    } catch (error) {
      toast.error(`${error.response.data.errors[0].message}`, {
        position: "top-right",
        autoClose: 4000,
        pauseHover: true,
        draggable: true,
      });
    }
  };

  const getParentCategory = async () => {
    try {
      axiosInstance.get(`/category/${categoryId}`).then((response) => {
        setCategories(response.data.data.children);
        // const displayCategories = books.map((map) => (() => )
        setLoading(true);
      });
    } catch (error) {
      setLoading(true);
    }
  };

  useEffect(() => {
    getParentCategory();
  }, []);

  const showSubCategory = (categoryId) => {
    setViewLoader(true);
    axiosInstance.get(`/category/${categoryId}`).then((response) => {
      setViewCategory(response.data.data);
      setViewLoader(false);
    });
  };

  const submit = (categoryId) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleDelete(categoryId),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  if (!loading || userLoading || activityLoading) {
    return (
      <div className="lds-ctn">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="grid-container">
        <ToastContainer />
        <header className="header">
          <div className="header__message me-5">
            <h2>Categories</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>

        <SellersSidebar />

        <main className="main">
          <h1 className="section-title">Activity Summary</h1>
          <div className="main-overview">
            <div className="overview-card">
              <div>
                <h2>All Sub Categories</h2>
                <div className="d-flex justify-content-between mt-4">
                  <h3>{activitySummary.total_parent_categories}</h3>
                </div>
              </div>
            </div>
            <div className="overview-card">
              <div>
                <h2>Total Sub Categories</h2>
                <div className="d-flex justify-content-between mt-4">
                  <h3>{activitySummary.total_children_categories}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <h1 className="section-title">All Sub Categories</h1>
            </div>
            <div className="col-lg-6 mb-2" align="right">
              <Link to="/create-category">
                <button className="btn btn-dark"> Create Category</button>
              </Link>
            </div>
          </div>
          <div className="main-overview">
            <div className="overview-card no-padding">
              <div className="table-responsive">
                {/* THE CHANGE STARTS HERE */}
                <div>
                  <div className="row">
                    <div className="col-3 serial-number table-header">S/N</div>
                    <div className="col-3 serial-number table-header">
                      Category Name
                    </div>
                    <div className="col-3 serial-number table-header">Slug</div>
                    <div className="col-3 serial-number table-header">
                      Action
                    </div>
                  </div>
                  <div>
                    {categories &&
                      categories.map((category, index) => {
                        return (
                          <div key={category.id}>
                            <div className="row first-row">
                              <div className="col-3 serial-number">
                                {index + 1}
                              </div>
                              <div className="col-3">{category.category}</div>
                              <div className="col-3">{category.slug}</div>
                              <div className="col-3">
                                <div className="nav-item dropdown">
                                  <Link
                                    className="nav-link main-nav-link "
                                    to="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i
                                      className="fa fa-chevron-down"
                                      align="right"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                  <ul
                                    className="dropdown-menu animate slideIn category-dropdown-item"
                                    aria-labelledby="navbarDropdown"
                                    style={{
                                      width: "50px !important",
                                      textAlign: "center",
                                    }}
                                  >
                                    <li>
                                      <div
                                        onClick={(e) =>
                                          showSubCategory(category.id)
                                        }
                                        className="dropdown-item"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                      >
                                        View
                                      </div>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/edit-category/${category.id}`}
                                        className=" dropdown-item"
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <div
                                        className="dropdown-item text-danger"
                                        onClick={(e) => submit(category.id)}
                                      >
                                        Delete
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="modal fade p-relative"
                                  id="exampleModal"
                                  tabIndex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                        >
                                          Category
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      {viewLoader ? (
                                        <div className="lds-ctnn">
                                          <div className="lds-ellipsis">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="modal-body d-flex mx-2">
                                            Category:{" "}
                                            {viewCategory.category &&
                                              viewCategory.category}
                                          </div>
                                          <div className="modal-body d-flex mx-2">
                                            Slug:{" "}
                                            {viewCategory.slug &&
                                              viewCategory.slug}
                                          </div>
                                          <div className="modal-body d-flex ">
                                            <div>Description:</div>
                                            <div className="mx-1">
                                              {viewCategory.description &&
                                                viewCategory.description}
                                            </div>
                                          </div>
                                          <div className="modal-body d-flex mx-2">
                                            Date Created:{" "}
                                            {viewCategory.createdAt &&
                                              dayjs(
                                                viewCategory.createdAt
                                              ).format("D MMMM YYYY")}
                                          </div>

                                          <div className="modal-body d-flex mx-2">
                                            Description:
                                            <div className="mx-2">
                                              {viewCategory.subCategories &&
                                                viewCategory.subCategories.map(
                                                  (category, index) => {
                                                    return (
                                                      <div key={category.id}>
                                                        {index + 1}{" "}
                                                        {category.subCategory}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {category.children &&
                              category.children.length > 0 &&
                              category.children.map((categoryOne) => {
                                return (
                                  <div key={categoryOne.id}>
                                    <div className="row row-inactive">
                                      <div className="col-3 serial-number">
                                        {index + 2}
                                      </div>
                                      <div className="col-3">
                                        {categoryOne.category &&
                                          categoryOne.category}
                                      </div>
                                      <div className="col-3">
                                        {categoryOne.slug && categoryOne.slug}
                                      </div>
                                      <div className="col-3">
                                        <div className="nav-item dropdown">
                                          <Link
                                            className="nav-link main-nav-link "
                                            to="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i
                                              className="fa fa-chevron-down"
                                              align="right"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                          <ul
                                            className="dropdown-menu animate slideIn category-dropdown-item"
                                            aria-labelledby="navbarDropdown"
                                            style={{
                                              width: "50px !important",
                                              textAlign: "center",
                                            }}
                                          >
                                            <li>
                                              <div
                                                onClick={(e) =>
                                                  showSubCategory(
                                                    categoryOne.id
                                                  )
                                                }
                                                className="dropdown-item"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                              >
                                                View
                                              </div>
                                            </li>
                                            <li>
                                              <Link
                                                to={`/edit-category/${categoryOne.id}`}
                                                className=" dropdown-item"
                                              >
                                                Edit
                                              </Link>
                                            </li>
                                            <li>
                                              <div
                                                className="dropdown-item text-danger"
                                                onClick={(e) =>
                                                  submit(categoryOne.id)
                                                }
                                              >
                                                Delete
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          className="modal fade p-relative"
                                          id="exampleModal"
                                          tabIndex="-1"
                                          aria-labelledby="exampleModalLabel"
                                          aria-hidden="true"
                                        >
                                          {viewLoader ? (
                                            <div
                                              className="spinner mx-auto"
                                              align="center"
                                              id="spinner"
                                              style={{
                                                position: "absolute",
                                                top: "calc(50% - 60px)",
                                                left: "calc(50% - 60px)",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                                margin: "auto",
                                              }}
                                            ></div>
                                          ) : (
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h5
                                                    className="modal-title"
                                                    id="exampleModalLabel"
                                                  >
                                                    Category
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                  ></button>
                                                </div>
                                                <div className="modal-body d-flex mx-2">
                                                  Category:{" "}
                                                  {viewCategory.category}
                                                </div>
                                                <div className="modal-body d-flex mx-2">
                                                  Slug: {viewCategory.slug}
                                                </div>
                                                <div className="modal-body d-flex ">
                                                  <div>Description:</div>
                                                  <div className="mx-1">
                                                    {viewCategory.description}
                                                  </div>
                                                </div>
                                                <div className="modal-body d-flex mx-2">
                                                  Date Created:{" "}
                                                  {dayjs(
                                                    viewCategory.createdAt
                                                  ).format("D MMMM YYYY")}
                                                </div>

                                                <div className="modal-body d-flex mx-2">
                                                  Description:
                                                  <div className="mx-2">
                                                    {viewCategory.subCategories &&
                                                      viewCategory.subCategories.map(
                                                        (category, index) => {
                                                          return (
                                                            <div
                                                              key={category.id}
                                                            >
                                                              {index + 1}
                                                              {
                                                                category.subCategory
                                                              }
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-bs-dismiss="modal"
                                                  >
                                                    Close
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {categoryOne.children &&
                                      categoryOne.children.length > 0 &&
                                      categoryOne.children.map(
                                        (categoryTwo) => {
                                          return (
                                            <div key={categoryTwo.id}>
                                              <div className="row first-row">
                                                <div className="col-3 serial-number">
                                                  {index + 3}
                                                </div>
                                                <div className="col-3">
                                                  {categoryTwo.category}
                                                </div>
                                                <div className="col-3">
                                                  {categoryTwo.slug}
                                                </div>
                                                <div className="col-3">
                                                  <div className="nav-item dropdown">
                                                    <Link
                                                      className="nav-link main-nav-link "
                                                      to="#"
                                                      id="navbarDropdown"
                                                      role="button"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <i
                                                        className="fa fa-chevron-down"
                                                        align="right"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </Link>
                                                    <ul
                                                      className="dropdown-menu animate slideIn category-dropdown-item"
                                                      aria-labelledby="navbarDropdown"
                                                      style={{
                                                        width:
                                                          "50px !important",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <li>
                                                        <div
                                                          onClick={(e) =>
                                                            showSubCategory(
                                                              categoryTwo.id
                                                            )
                                                          }
                                                          className="dropdown-item"
                                                          data-bs-toggle="modal"
                                                          data-bs-target="#exampleModal"
                                                        >
                                                          View
                                                        </div>
                                                      </li>
                                                      <li>
                                                        <Link
                                                          to={`/edit-category/${categoryTwo.id}`}
                                                          className=" dropdown-item"
                                                        >
                                                          Edit
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <div
                                                          className="dropdown-item text-danger"
                                                          onClick={(e) =>
                                                            submit(
                                                              categoryTwo.id
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                  <div
                                                    className="modal fade p-relative"
                                                    id="exampleModal"
                                                    tabIndex="-1"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                  >
                                                    {viewLoader ? (
                                                      <div
                                                        className="spinner mx-auto"
                                                        align="center"
                                                        id="spinner"
                                                        style={{
                                                          position: "absolute",
                                                          top: "calc(50% - 60px)",
                                                          left: "calc(50% - 60px)",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          textAlign: "center",
                                                          margin: "auto",
                                                        }}
                                                      ></div>
                                                    ) : (
                                                      <div className="modal-dialog">
                                                        <div className="modal-content">
                                                          <div className="modal-header">
                                                            <h5
                                                              className="modal-title"
                                                              id="exampleModalLabel"
                                                            >
                                                              Category
                                                            </h5>
                                                            <button
                                                              type="button"
                                                              className="btn-close"
                                                              data-bs-dismiss="modal"
                                                              aria-label="Close"
                                                            ></button>
                                                          </div>
                                                          <div className="modal-body d-flex mx-2">
                                                            Category:{" "}
                                                            {
                                                              viewCategory.category
                                                            }
                                                          </div>
                                                          <div className="modal-body d-flex mx-2">
                                                            Slug:{" "}
                                                            {viewCategory.slug}
                                                          </div>
                                                          <div className="modal-body d-flex ">
                                                            <div>
                                                              Description:
                                                            </div>
                                                            <div className="mx-1">
                                                              {
                                                                viewCategory.description
                                                              }
                                                            </div>
                                                          </div>
                                                          <div className="modal-body d-flex mx-2">
                                                            Date Created:{" "}
                                                            {dayjs(
                                                              viewCategory.createdAt
                                                            ).format(
                                                              "D MMMM YYYY"
                                                            )}
                                                          </div>

                                                          <div className="modal-body d-flex mx-2">
                                                            Description:
                                                            <div className="mx-2">
                                                              {viewCategory.subCategories &&
                                                                viewCategory.subCategories.map(
                                                                  (
                                                                    category,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        key={
                                                                          category.id
                                                                        }
                                                                      >
                                                                        {index +
                                                                          1}{" "}
                                                                        {
                                                                          category.subCategory
                                                                        }
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                            </div>
                                                          </div>
                                                          <div className="modal-footer">
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                              data-bs-dismiss="modal"
                                                            >
                                                              Close
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              {categoryTwo.children &&
                                                categoryTwo.children.length >
                                                  0 &&
                                                categoryTwo.children.map(
                                                  (categoryThree) => {
                                                    return (
                                                      <div
                                                        key={categoryThree.id}
                                                      >
                                                        <div className="row row-inactive">
                                                          <div className="col-3 serial-number">
                                                            {index + 4}
                                                          </div>
                                                          <div className="col-3">
                                                            {
                                                              categoryThree.category
                                                            }
                                                          </div>
                                                          <div className="col-3">
                                                            {categoryThree.slug}
                                                          </div>
                                                          <div className="col-3">
                                                            <div className="nav-item dropdown">
                                                              <Link
                                                                className="nav-link main-nav-link "
                                                                to="#"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                              >
                                                                <i
                                                                  className="fa fa-chevron-down"
                                                                  align="right"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </Link>
                                                              <ul
                                                                className="dropdown-menu animate slideIn category-dropdown-item"
                                                                aria-labelledby="navbarDropdown"
                                                                style={{
                                                                  width:
                                                                    "50px !important",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <li>
                                                                  <div
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      showSubCategory(
                                                                        categoryThree.id
                                                                      )
                                                                    }
                                                                    className="dropdown-item"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                  >
                                                                    View
                                                                  </div>
                                                                </li>
                                                                <li>
                                                                  <Link
                                                                    to={`/edit-category/${categoryThree.id}`}
                                                                    className=" dropdown-item"
                                                                  >
                                                                    Edit
                                                                  </Link>
                                                                </li>
                                                                <li>
                                                                  <div
                                                                    className="dropdown-item text-danger"
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      submit(
                                                                        categoryThree.id
                                                                      )
                                                                    }
                                                                  >
                                                                    Delete
                                                                  </div>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                            <div
                                                              className="modal fade p-relative"
                                                              id="exampleModal"
                                                              tabIndex="-1"
                                                              aria-labelledby="exampleModalLabel"
                                                              aria-hidden="true"
                                                            >
                                                              {viewLoader ? (
                                                                <div
                                                                  className="spinner mx-auto"
                                                                  align="center"
                                                                  id="spinner"
                                                                  style={{
                                                                    position:
                                                                      "absolute",
                                                                    top: "calc(50% - 60px)",
                                                                    left: "calc(50% - 60px)",
                                                                    justifyContent:
                                                                      "center",
                                                                    alignItems:
                                                                      "center",
                                                                    textAlign:
                                                                      "center",
                                                                    margin:
                                                                      "auto",
                                                                  }}
                                                                ></div>
                                                              ) : (
                                                                <div className="modal-dialog">
                                                                  <div className="modal-content">
                                                                    <div className="modal-header">
                                                                      <h5
                                                                        className="modal-title"
                                                                        id="exampleModalLabel"
                                                                      >
                                                                        Category
                                                                      </h5>
                                                                      <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                      ></button>
                                                                    </div>
                                                                    <div className="modal-body d-flex mx-2">
                                                                      Category:{" "}
                                                                      {
                                                                        viewCategory.category
                                                                      }
                                                                    </div>
                                                                    <div className="modal-body d-flex mx-2">
                                                                      Slug:{" "}
                                                                      {
                                                                        viewCategory.slug
                                                                      }
                                                                    </div>
                                                                    <div className="modal-body d-flex ">
                                                                      <div>
                                                                        Description:
                                                                      </div>
                                                                      <div className="mx-1">
                                                                        {
                                                                          viewCategory.description
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                    <div className="modal-body d-flex mx-2">
                                                                      Date
                                                                      Created:{" "}
                                                                      {dayjs(
                                                                        viewCategory.createdAt
                                                                      ).format(
                                                                        "D MMMM YYYY"
                                                                      )}
                                                                    </div>

                                                                    <div className="modal-body d-flex mx-2">
                                                                      Description:
                                                                      <div className="mx-2">
                                                                        {viewCategory.subCategories &&
                                                                          viewCategory.subCategories.map(
                                                                            (
                                                                              category,
                                                                              index
                                                                            ) => {
                                                                              return (
                                                                                <div
                                                                                  key={
                                                                                    category.id
                                                                                  }
                                                                                >
                                                                                  {index +
                                                                                    1}{" "}
                                                                                  {
                                                                                    category.subCategory
                                                                                  }
                                                                                </div>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                      <button
                                                                        type="button"
                                                                        className="btn btn-secondary"
                                                                        data-bs-dismiss="modal"
                                                                      >
                                                                        Close
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProtectedRoutes(AllSubCategories);
