import React, { useContext } from "react";
import { useGetTagAnalytics } from "../../../hooks/useGetTagAnalytics";
import { ProtectedRoutes } from "../../../components/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import { AppContext } from "../../../components/AppState";
import {
  Capitalize,
  convertDateFormat,
} from "../../../helpers/hellpersFunctions";
import SellersSidebar from "../dashboardComponents/SideBar";
import PaginationComponent from "../../../components/PaginationComponent";
import Search from "../dashboardComponents/Search";
import { useGetEventTagCount } from "../../../hooks/useGetEventTagCount";
import TableLoader from "../../../components/skeletonLoaders/TableLoader";

const Analytics = () => {
  const { user } = useContext(AppContext);
  const {
    eventTagData,
    setCurrentPage,
    setSearch,
    loading,
    ITEMS_PER_PAGE,
    currentPage,
    totalItems,
    noMatch,
  } = useGetEventTagCount();

  return (
    <>
      <div className="grid-container">
        <ToastContainer />
        <header className="header">
          <div className="header__message me-5">
            <h2>All Event Tag Analytics</h2>
          </div>

          <div className="user-area ms-auto">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 user-area-art">
                {user.firstName && user.firstName.charAt(0).toUpperCase()}
              </div>
              <div className="flex-grow-1 ms-2">
                {user.firstName &&
                user.firstName.length + " " + user.LastName.length > 15 ? (
                  <p className="">
                    {Capitalize(user.firstName).slice(0, 15)}...
                  </p>
                ) : (
                  <p className="">
                    {user.firstName && Capitalize(user.firstName)}{" "}
                    {user.LastName}{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </header>

        <SellersSidebar />

        <main className="main">
          <div className="section-title-ctn">
            <h1 className="section-title">Latest Products</h1>
            <div className="header__search">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          {/* <h1 className="section-title">All Orders</h1> */}
          {loading ? (
            <TableLoader />
          ) : (
            <>
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date created</th>
                          <th>Tag</th>
                          <th scope="col">User</th>
                          <th scope="col">User Email</th>
                          <th scope="col">User Phone number</th>
                          <th scope="col">Category</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventTagData &&
                          eventTagData.map((tag, index) => {
                            return (
                              <tr key={tag.id}>
                                <td>
                                  {tag.createdAt &&
                                    convertDateFormat(tag.createdAt)}
                                </td>
                                <td>{tag?.tag}</td>
                                <td>{tag?.userName && tag.userName}</td>
                                <td>{tag?.userEmail && tag.userEmail}</td>
                                <td>{tag?.userPhone && tag.userPhone}</td>
                                <td>{tag?.categor && tag.catgory}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>No results found</h4>
                  <p>
                    No search matched your criteria. Try searching for something
                    else.
                  </p>
                </div>
              ) : (
                <div className="pag-ctn">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(Analytics, ["SUPER_ADMIN", "MARKETPLACE_ADMIN"]);
